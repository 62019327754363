import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../state/store';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


import {
    faBars,
    faInfo,
    faHouse,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'flowbite-react';



import {
    toggleTheme,
    setActiveComponent,
    setShowSideBar,
} from '../state/user/userSlice'
import {
    SubComponentChatInterface,
    SubComponentUserRegistration,
    SubComponentCourseOutline,
    SubComponentFooter,
    SubComponentExplore,
    SubComponentGeneralTutorOutline,
    SubComponentGeneralTutorChatInterface,
    SubComponentStripeDashboard,
} from './_subComponents';
import {
    TwButton,
} from './_items';
import {
    TwIconTheme,
} from './_icons';

export function ComponentSideBar() {
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch<AppDispatch>();
    return (
        <>
            {user.activeComponent === 'SubComponentChatInterface' || user.activeComponent === 'SubComponentGeneralTutorChatInterface' ? (
                <div className='p-2'>
                    <div className={'inline-flex items-end md:!hidden float-right absolute top-0 right-0 text-slate-950 dark:text-slate-50'}>
                        <div
                            className={`p-1 w-4/4 p-2 flex items-center font-bold text-center cursor-pointer
								float-right
								text-xl
							`}
                            onClick={() => dispatch(setShowSideBar(user.showSideBar === false))}
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </div>
                    </div>
                </div>
            ) : (null)}
            {user.activeComponent === 'SubComponentGeneralTutorChatInterface' ? (
                <SubComponentGeneralTutorOutline>
                </SubComponentGeneralTutorOutline>
            ) : (
                <SubComponentCourseOutline>
                </SubComponentCourseOutline>
            )}
        </>
    )
}
export function ComponentMainTop() {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate()
    //
    return (
        <>
            <div className={'flex flex-row basis-2/12 h-auto'}>
                {user.activeComponent === 'SubComponentChatInterface' || user.activeComponent === 'SubComponentGeneralTutorChatInterface' ? (
                    <div
                        className={`p-1 w-4/4 p-2 flex items-center font-bold text-center cursor-pointer
								float-right
								text-xl
							`}
                        onClick={() => dispatch(setShowSideBar(user.showSideBar === false))}
                    >
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                ) : (null)}
                <div
                    className={`p-1 w-4/4 p-2 flex items-center font-bold text-center cursor-pointer
								float-right
								text-xl
							`}
                    onClick={() => {
                        navigate('/home')
                        dispatch(setActiveComponent('SubComponentExplore'));
                    }}
                >
                    <FontAwesomeIcon icon={faHouse} />
                </div>
            </div>
            <div className={'flex flex-col basis-8/12'}>
                {!user.hid || user.activeComponent !== 'SubComponentChatInterface' ? (null) : (
                    <>
                        {'description' in course.courseLesson && course.courseLesson.description ? (
                            <div className='m-auto text-[16px]'>
                                {course.courseLesson.description.topic_name}
                            </div>
                        ) : (
                            null
                        )}
                    </>
                )}
                {!user.hid || user.activeComponent !== 'SubComponentGeneralTutorChatInterface' ? (null) : (
                    <>
                        {(
                            'generalTutorLesson' in course ? (
                                'lessonpoints' in course.generalTutorLesson && course.generalTutorLesson.lessonpoints instanceof Array &&
                                    typeof course.activeGeneralTutorThread == 'number' ? (
                                    <div className='m-auto text-[16px]'>
                                        {course.generalTutorLesson.lessontitle}
                                    </div>
                                ) : (null)
                            ) : (null)
                        )}
                    </>
                )}

            </div >
            <div className={'flex flex-row-reverse basis-2/12'}>
                <span onClick={() => dispatch(toggleTheme())} className={`flex justify-center items-center h-full p-2 rounded-md menu_item cursor-pointer`}>
                    <TwIconTheme />
                </span >
            </div>

        </>
    )
}
export function ComponentMainBody() {
    return (
        <>
            <SubComponentStripeDashboard>
            </SubComponentStripeDashboard>


            <SubComponentUserRegistration>
            </SubComponentUserRegistration>

            <SubComponentExplore>
            </SubComponentExplore>

            <SubComponentChatInterface>
            </SubComponentChatInterface>

            <SubComponentGeneralTutorChatInterface>
            </SubComponentGeneralTutorChatInterface>



        </>
    )
}
export function ComponentMainBottom() {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    //
    const [openInformationModal, setInformationOpenModal] = useState(
        urlParams.get('vision') !== null ||
            urlParams.get('terms') !== null ||
            urlParams.get('privacy') !== null ||
            urlParams.get('contact') !== null ? true : false
    )

    return (
        <>
            <div className={'flex flex-row basis-1/12 items-end'}>
            </div>
            <div className={'flex flex-row basis-10/12'}>
                <div className='text-center w-full mt-4'>
                    <div className='m-auto w-12/12'>
                        <span className='text-xs m-auto'>
                            Remember: Check important information
                        </span>
                    </div>
                </div>
            </div>
            <div className={'flex flex-row basis-1/12 justify-end'}>
                <div
                    className={`p-1 p-2 flex items-end font-bold text-center cursor-pointer
							text-sm float
						`}
                    onClick={() => setInformationOpenModal(true)}
                >
                    <FontAwesomeIcon icon={faInfo} />
                </div>
                <Modal show={openInformationModal} onClose={() => setInformationOpenModal(false)}>
                    <Modal.Header>Information</Modal.Header>
                    <Modal.Body>
                        <SubComponentFooter />
                    </Modal.Body>
                    <Modal.Footer>
                        <TwButton
                            className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                            onClick={() => setInformationOpenModal(false)}>Close</TwButton>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}
