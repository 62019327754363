import React from 'react'
import {
	MONTHS,
	WEEK_DAYS,
	DAYS_IN_YEAR,
	DEFAULT_SQUARE_GAP,
	DEFAULT_SQUARE_SIZE,
} from '../constants'
import {
	getRandomCount,
	transformCount,
	transformPixelsToNumber,
} from '../utils'
import styled from 'styled-components'

export interface IProps {
	colour?: string[]
	squareNumber?: number
	count: number[]
	squareGap?: string
	squareSize?: string
	fontSize?: string
	tooltipContent?: string
}

// Styled components with transient props (prefixed with $)
const Wrapper = styled.div<{ $fontSize: string }>`
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,
		Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
		'Segoe UI Symbol';
	font-size: ${(props) => props.$fontSize};
	box-sizing: border-box;
	ul {
		padding-inline-start: 0;
		list-style: none;
	}
`

const Graph = styled.div`
	padding: 5px;
	margin: 5px;
	display: inline-grid;
	grid-template-areas:
		'empty months'
		'days squares';
	grid-template-columns: auto 1fr;
	grid-gap: 10px;
`

const Months = styled.ul<{ $weekWidth: string }>`
	padding-inline-start: 0;
	grid-area: months;
	margin-bottom: 0;
	display: grid;
	grid-template-columns:
		calc(${(props) => props.$weekWidth} * 4) /* jan */
		calc(${(props) => props.$weekWidth} * 4) /* feb */
		calc(${(props) => props.$weekWidth} * 4) /* mar */
		calc(${(props) => props.$weekWidth} * 5) /* apr */
		calc(${(props) => props.$weekWidth} * 4) /* may */
		calc(${(props) => props.$weekWidth} * 4) /* jun */
		calc(${(props) => props.$weekWidth} * 5) /* jul */
		calc(${(props) => props.$weekWidth} * 4) /* aug */
		calc(${(props) => props.$weekWidth} * 4) /* sep */
		calc(${(props) => props.$weekWidth} * 5) /* oct */
		calc(${(props) => props.$weekWidth} * 4) /* nov */
		calc(${(props) => props.$weekWidth} * 5) /* dec */;
`

const Days = styled.ul<{ $squareSize: string; $squareGap: string }>`
	margin-block-end: 0;
	margin-block-start: 0;
	grid-area: days;
	display: grid;
	grid-gap: ${(props) => props.$squareGap};
	grid-template-rows: repeat(7, ${(props) => props.$squareSize});
`

const SquaresList = styled.ul<{ $squareSize: string; $squareGap: string }>`
	margin-top: 0;
	margin-block-start: 0;
	grid-area: squares;
	display: grid;
	grid-gap: ${(props) => props.$squareGap};
	grid-template-rows: repeat(7, ${(props) => props.$squareSize});
	z-index: 1;
	grid-auto-flow: column;
	grid-auto-columns: ${(props) => props.$squareSize};
`

const SquareListItem = styled.li<{ $level: number; $colours: string[] }>`
	border-radius: 3px;
	border: 1px rgba(27, 31, 35, 0.06) solid;
	background-color: ${(props) => props.$colours[props.$level]};
	&[data-tooltip] {
		position: relative;
		cursor: pointer;
	}

	&[data-tooltip]:before,
	&[data-tooltip]:after {
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
	}

	&[data-tooltip]:before {
		position: absolute;
		z-index: 999;
		bottom: 150%;
		left: 100%;
		margin-bottom: 5px;
		margin-left: -90px;
		padding: 5px;
		width: 150px;
		border-radius: 3px;
		background-color: #000;
		background-color: hsla(0, 0%, 20%, 0.9);
		color: #fff;
		content: attr(data-tooltip);
		text-align: center;
		font-size: 10px;
		line-height: 1.2;
	}

	&[data-tooltip]:after {
		position: absolute;
		bottom: 150%;
		left: 50%;
		margin-left: -5px;
		width: 0;
		border-top: 5px solid hsla(0, 0%, 20%, 0.9);
		border-right: 5px solid transparent;
		border-left: 5px solid transparent;
		content: ' ';
		font-size: 0;
		line-height: 0;
		z-index: inherit;
	}

	&[data-tooltip]:hover:before,
	&[data-tooltip]:hover:after {
		visibility: visible;
		opacity: 1;
	}
`

const Heatmap: React.FC<IProps> = (props: IProps) => {
	// Destructure and set default values for props
	const colour = props.colour || ['#ebedf0', '#c6e48b', '#40c463', '#30a14e', '#216e39']
	const squareNumber = props.squareNumber || DAYS_IN_YEAR
	const count = props.count || getRandomCount(squareNumber)
	const level = count.map((i: number) => transformCount(i))
	const squareGap = props.squareGap || DEFAULT_SQUARE_GAP
	const squareSize = props.squareSize || DEFAULT_SQUARE_SIZE
	const fontSize = props.fontSize || '10px'
	const weekWidth = String(transformPixelsToNumber(squareGap) + transformPixelsToNumber(squareSize)) + 'px'

	return (
		<Wrapper $fontSize={fontSize}>
			<Graph>
				<Months $weekWidth={weekWidth}>
					{MONTHS.map((month, i) => (
						<li key={i}>{month}</li>
					))}
				</Months>
				<Days $squareSize={squareSize} $squareGap={squareGap}>
					{WEEK_DAYS.map((day, i) => (
						<li key={i}>{day}</li>
					))}
				</Days>
				<SquaresList $squareSize={squareSize} $squareGap={squareGap}>
					{[...Array(squareNumber)].map((_, i) => (
						<SquareListItem
							key={`square_list_item_${i}`}
							data-tooltip={props.tooltipContent || `${count[i]} Actions`}
							$colours={colour}
							$level={level[i]}
						/>
					))}
				</SquaresList>
			</Graph>
		</Wrapper>
	)
}

export default Heatmap

