import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../state/store';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination, Navigation } from 'swiper/modules';
import InfiniteScroll from "react-infinite-scroll-component";
import { ThemeProvider, createTheme } from '@mui/material/styles';


import { Heatmap } from "../packages/contribution_heatmap"







import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'

import 'katex/dist/katex.min.css'
import ReCAPTCHA from "react-google-recaptcha";
import { Modal, Timeline, Card } from 'flowbite-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HiArrowNarrowRight } from 'react-icons/hi';
import {
    faMagnifyingGlass,
    faGraduationCap,
    faChartLine,
    faRotateRight,
    faBook,
    faCircleExclamation,
    faSquareCheck,
    faSquareXmark,
    faGripLinesVertical,
    faCrown,
    faSpinner,
    faDumbbell,
    faChartPie,
    faGear,
    faEnvelope,
    faChevronLeft,
    faChevronRight,
    faArrowsRotate,
} from '@fortawesome/free-solid-svg-icons'
import { faTiktok } from '@fortawesome/free-brands-svg-icons'

// Stripe
import { loadConnectAndInitialize } from '@stripe/connect-js';
import {
    ConnectComponentsProvider,
    ConnectPayouts,
} from "@stripe/react-connect-js";
import 'katex/dist/katex.min.css'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';

import {
    CaptchaComponent,
    setActiveComponent,
    setActiveSubComponent,
    userLogin,
    userLogout,
    userForgotPassword,
    userSignUp,
    userResetPassword,
    userActivation,
    userContact,
    userAffiliateAccountCreation,
    userGetAffiliateTally,
    setShowSideBar,
    setnavTab,
    userCreateGuest,
} from '../state/user/userSlice'
import {
    setActiveThread,
    courseGetCatagoryList,
    courseGetSubscriptionList,
    courseGetCatagoryCourses,
    courseGetLesson,
    CourseState,
    setCourseProgress,
    courseGetLessonIntroduction,
    generalTutorDeleteLesson,
    generalTutorUpdateLesson,
    setGeneralTutorActiveLesson,
    generalTutorGetLessonPage,
    generalTutorGetLesson,
    setActiveGeneralTutorThread,
    setRequestGeneralTutorLessonCreationResponse,
    courseGetGeneralTutorLessonCreationRequestTicket,
    runGeneralTutorLessonCreationStream,
    courseGetPerformanceStatistics,
    resetVideosList,
    myjsonrepair,
    courseGetYouTubeSearch,
    courseGetRevisionAssessment,
    generalTutorGetLessonList,
    generalTutorCreateLesson,
    courseGetCourseSearch,
    resetCourseSearchList,
    generalTutorCreateCourse,
    generalTutorGetCourseList,
} from '../state/course/courseSlice'

import {
    TwLoading,
    TwButton,
    TwAnchor,
    TwCourseCardBasic,
    TwHome,
    TwChatThread,
    TwAccordion,
    TwNewAssessmentUI,
    TwAssessmentDetail,
    TwGeneralTutorChatThread,
    TwGeneralTutorNewAssessmentUI,
    TwGeneralTutorAssessmentDetail,
    TwChatInput,
    TwGeneralTutorChatInput,
    TwProgressBar,
    TwGeneralTutorCardBasic,
    TwCourseCardBasicDisplay,
    TwGuestLoginModal,
} from './_items';
import { TwSprkles } from './_icons';

function PrivateComponentVision() {
    return (
        <div className={`
		flex flex-col md:w-10/12 w-12/12 m-auto p-1 px-0 text-[9px] md:text-sm
		`}>
            <div className={`
			flex flex-row  w-full p-4 border-b px-1 pb-1 
			`}>
                <div className={`
						p-1
						rounded-md
						basis-1/12
					`}>
                </div>


            </div>
            <div className='pb-48 border-b w-3/4 m-auto'>
                <h1 className='text-xl mt-4 text-center'>The best place to learn with Artificial Intelligence.</h1>
            </div>
        </div>
    )
}
function PrivateComponentAffiliateOnBoarding(props: { setAffiliateModal: Function }) {
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch<AppDispatch>();
    return (
        <div className={`
		flex flex-col md:w-10/12 w-12/12 m-auto p-1 px-0 text-[9px] md:text-sm
		`}>
            <div className={`
			flex flex-row  w-full p-4 border-b px-1 pb-1 
			`}>
                <div className={`
						p-1
						rounded-md
						basis-1/12
					`}>
                </div>
            </div>
            <div className='pb-48 border-b w-3/4 m-auto'>
                <h1 className='text-xl mt-4 text-center'>Join or our affiliate program :)</h1>
                <button
                    type="submit"
                    className="border border-cyan-500 rounded-full w-3/4 text-center py-3 bg-gradient-to-r from-gray-50 to-white focus:outline-none my-1 flex items-center justify-center shadow-lg transform transition-transform duration-200 ease-in-out hover:scale-105 m-auto text-black hover:shadow-cyan-500/50 mt-8"
                >

                    <div className='flex flex-row w-[90%] justify-between'
                        onClick={() => {
                            if (!('hid' in user) || !user.hid || !('accessToken' in user && user.accessToken)) {
                                dispatch(setActiveComponent('SubComponentUserRegistration'));
                                dispatch(setActiveSubComponent('PrivateComponentSignUp'));
                                props.setAffiliateModal(false)
                            } else {
                                dispatch(userAffiliateAccountCreation({ accessToken: user.accessToken }))
                                if (!user.is_affiliate) {
                                    dispatch(userLogout())
                                }
                            }
                        }}>
                        <TwSprkles
                            className='hover:cursor-pointer fill-cyan-500 !inline-block align-middle !w-6 !h-6 ml-1'
                        />
                        {user.loading.userAffiliateAccountCreation ? (
                            <TwLoading className="font-bold text-baseline" />
                        ) : (
                            user.is_affiliate ? (
                                <span className="font-bold text-baseline">View Account</span>
                            ) : (
                                <span className="font-bold text-baseline">Join Affiliate Program</span>
                            )
                        )}
                        <TwSprkles
                            className='hover:cursor-pointer fill-cyan-500 !inline-block align-middle !w-6 !h-6 ml-1'
                        />
                    </div>
                </button>
            </div>
        </div >
    )
}
export function PrivateComponentPrivacy() {
    const CompanyName = process.env.REACT_APP_COMPANY_NAME
    const InfoContact = process.env.REACT_APP_COMPANY_INFO_CONTACT
    const TeamContact = process.env.REACT_APP_COMPANY_INFO_CONTACT

    return (
        <div className={`
		flex flex-col md:w-10/12 w-12/12 m-auto p-1 px-0 text-[9px] md:text-sm
		`}>
            <div className={`
			flex flex-row  w-full p-4 border-b px-1 pb-1 
			`}>
                <div className='ml-3 basis-10/12 pb-4'>
                    <span className='text-4xl'>
                        Privacy & Cookies Policy
                    </span>
                </div>
            </div>
            <div className='pb-48 border-b'>
                <p><strong><span className='block text-2xl mt-3 mb-1'>Introduction &amp; General Terms</span></strong>
                    At {CompanyName} we are committed to protecting the personal information we hold about our members, newsletter subscribers and website users or any other data subject whose personal information we hold. Personal information as outlined in this policy is being processed by {CompanyName} Ltd (<strong>“we”, “our”, “us”, “{CompanyName}</strong><strong>”</strong><strong>)</strong>.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>Our commitment to you</span></strong>
                    We are committed to managing &amp; safeguarding your personal information in accordance with current legislation and best practice. Our aim is to be responsible, relevant and secure when using your data. Whenever you provide personal information we will treat that information in accordance with this privacy policy. We will process the personal information you have supplied to us to conduct and manage our business to enable us to give you the most appropriate marketing, information, service and products and provide the best and most secure experience. These are what we consider to be our ‘Legitimate Interests’.&nbsp;We endeavour at all times to keep your data accurate and secure, and to honour your data preferences with regard to receipt of direct marketing e.g. email.</p>
                <p>We operate websites that may contain hyperlinks to websites owned and operated by third parties. These third party websites have their own privacy policies, and are also likely to use cookies, and we therefore urge you to review them. They will govern the use of personal information you submit when visiting these websites, which may also be collected by cookies. We do not accept any responsibility or liability for the privacy practices of such third party websites and your use of such websites is at your own risk.</p>
                <p>We reserve the right to revise or amend this Privacy Policy at any time to reflect changes to our business or changes in the law. &nbsp;Where these changes are significant we will endeavour to email all of our registered users to make sure that they are informed of such changes. However, it is your responsibility to check this Privacy Policy before each use of the {CompanyName} website.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>Who we are</span></strong>
                    {CompanyName} is an education resource website that aims to help students revise for GCSE, A Level, and University (Level 6) exams; primaraly for STEM subjects. {CompanyName} Also aims to be a resource management and distribution tool for independant educators (Tutors), small organisations (Tuition centers) and Schools.</p>
                <p id='yourdata'><strong><span className='block text-2xl mt-3 mb-1'>What data we collect</span></strong>
                    When you send us information, submit an online form or when you visit our website, we may collect both personal and pseudonymous data about you.<br />
                    When we talk about personal data we mean any information that can identify you as an individual, such as your name and postal address. Pseudonymous data doesn’t identify you as a person but it might be used in aggregate, for instance when you participate in a survey or when we analyse audience interaction with our products and services.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>Data you provide to us</span></strong>
                    When you participate in, access, purchase or sign up to any of our services, activities or online content, such as membership subscriptions, newsletters, competitions or events, we receive personal information about you. This can consist of information such as your name, email address, postal address, telephone or mobile number and school, as well as information collected about your use of our services (such as when you enter our sites and how long you stay on them).</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>Data we collect through your use of our websites</span></strong>
                    We may collect information about how you use our websites or other content online, and the device(s) you use to access the services. This includes collecting unique online identifiers such as IP addresses, which are numbers that can uniquely identify a specific computer or other network device on the internet. Anonymous data will be recorded for the purpose of reporting web traffic statistics, advertisement ‘click-throughs’, and/or other activities on our sites.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>How we use your data</span></strong>
                    We may use the information you share with us and the information we collect through your use of our sites in the ways described below and as described at the time of capture.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>Personalisation</span></strong>
                    We will use the data collected from your direct interaction with the website in order to personalise your experince, those interactions include but are not limited to: Time spent studying on a page, Number of questions answered etc.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>Newsletters</span></strong>
                    We will use the details you have shared to send you the newsletter to which you have subscribed. We will provide a way for you to unsubscribe on every newsletter we send to you. Details you share with us for the purpose of newsletter subscription will never be shared with a third party.</p>
                <p><span className='block text-2xl mt-3 mb-1'><strong>Events</strong></span>
                    We will use the details you have shared to manage your registration and entry into our event. This may include contact via telephone, email, SMS and post with information relating to the event you have registered to attend. Your data will not be added to any marketing lists unless you have consented to this, and will only be shared with a third party where they were named at the point of data capture and you explicitly agreed to the sharing of your data or where they are a third party who we have appointed to manage the delivery of the event.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>Membership Subscription</span></strong>
                    We will use the details you have shared to manage your subscription to our website, which includes sending you login details, managing access to your online account, taking secure payments, sending you details on how to use your membership subscription and achieve the most out of your revision, to handle any customer query you may have and to contact you via telephone, email, SMS and post with information relating to your membership subscription. e.g. to notify you of the expiry date of your subscription or to remind you of a password or to notify you of updates to our privacy policy.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>Customer Services</span></strong>
                    We will use the information you provide to deliver customer services related to your purchase and to respond to inquiries or requests that you direct to us. Your data will not be added to any marketing lists unless you have consented to this, and will only be shared with third parties where we have appointed them to manage specific aspects of your customer care.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>Research</span></strong>
                    We may use the information you provide to invite you to participate in surveys and focus groups about our products, advertisers and services. Participation is always voluntary and the answers you provide are anonymous and aggregated to support our learnings and inform changes we can make to improve our products and services. Your data will not be added to any marketing lists unless you have consented to this and were informed at the point of capture and will only be shared with third parties where we have appointed them to manage research activity on our behalf.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>Direct Marketing</span></strong>
                    We may use the details you share with us to send you offers and promotions by email, post, telephone or SMS wherever we have legitimate interest grounds to do so &nbsp;and where you have not objected. We will provide a way for you to opt-out of receiving marketing promotions both at the time of data capture and everytime we contact you thereafter. You can also email&nbsp;{TeamContact} to request your details are suppressed from all promotional campaigns. We won’t use your personal data for marketing purposes at all if you’ve told us not to.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>Legitimate Interest</span></strong>
                    We may process your personal data on the basis of a legitimate interest which may include promoting similar products or services via direct marketing activity, to manage supression and unsubscribe requests received from you, to identify and prevent fraudulent behaviour, maintaining the security of our system, data analytics, enhancing, modifying or improving our services, identifying usage trends and determining the effectiveness of our campaigns.<br />
                    Whenever we process data for these purposes we will ensure that we always keep your personal data rights in high regard and take account of these rights.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>Cookies Policy</span></strong>
                    We use cookies and similar technologies across our website to improve its performance and enhance your user experience. This policy explains how we do that.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>What are cookies?</span></strong>
                    Cookies are small text files which a website may put on your computer or mobile device when you visit a site or page. The cookie will help the website, or another website, to recognise your device the next time you visit. Web beacons or other similar files can also do the same thing. We use the term “cookies” in this policy to refer to all files that collect information in this way.</p>
                <p>Cookies serve many functions. For example, they can help us to remember your username and preferences, analyse how well our website is performing, or even allow us to recommend content we believe will be most relevant to you.</p>
                <p>Certain cookies may record personal information – for example, if you click “Remember me” when logging in, a cookie will store your username. Most cookies won’t collect information that identifies you personally, and will instead allow us to collect more general information such as how users arrive at and use our websites.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>What sort of cookies does {CompanyName} use?</span></strong>
                    Generally, our cookies perform up to three different functions:</p>
                <p><strong>1. Essential cookies</strong><br />
                    These cookies are essential in order to enable you to move around the website and use its features, such as accessing secure areas of the website. Without these cookies services you have asked for, like shopping baskets or e-billing, cannot be provided. Because some website features will not function without cookies of this type, they are designated as ‘strictly necessary’ and are exempted from the regulations which require your consent to place cookies on your device.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>2. Performance Cookies</span></strong>
                    These cookies allow us to collect information about how visitors use a website, for instance which pages visitors visit most often, and if they get error messages from web pages. These cookies don’t record information that personally identifies a visitor. All information these cookies record is aggregated and anonymous. It is only used to improve how a website works.<br />
                    <strong>By using the {CompanyName} website you agree we may place these types of cookies on your device, unless you have disabled them.</strong></p>
                <p><strong>3. Functionality Cookies</strong><br />
                    These cookies allow a website to remember choices you make (such as your user name, language or the region you are in) and provide enhanced, personalised features. For instance, a website may be able to provide you with local weather reports or traffic news by storing in a cookie the region in which you are currently located. These cookies can also be used to remember changes you have made to text size, fonts and other parts of web pages that you can customise. They may also be used to provide services you have asked for such as watching a video or commenting on a blog. The information these&nbsp;cookies record may be anonymised and they cannot track your browsing activity on other websites.<br />
                    <strong>By using the {CompanyName} website you agree we may place these types of cookies on your device, unless you have disabled them.</strong></p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>Does anyone else use cookies on the {CompanyName} website?</span></strong>
                    We also use or allow third parties to serve cookies that fall into the three categories above. For example, like many companies, we use Google Analytics to help us monitor our website traffic. We may also use third party cookies to help us with market research, revenue tracking, improving site functionality and monitoring compliance with our terms and conditions and copyright policy.</p>
                <p>If you click on a hyperlink from the {CompanyName} website to any third party websites (e.g. if you ‘share’ content from the {CompanyName} website with friends or colleagues through social networks), you may be sent cookies from these third party websites. &nbsp;Third party websites have their own privacy and cookie policies which {CompanyName} cannot control. Please check the third-party websites for more information about their cookies and how to manage them.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>Can a website user block cookies?</span></strong>
                    As we’ve explained above, cookies help you to get the most out of our websites.</p>
                <p>The first time you access our website after 29 April 2022, you should see a banner which explains that by continuing to access our sites, you are consenting to our use of cookies.</p>
                <p>However, if you do wish to disable our cookies then please follow the instructions on&nbsp;<a href="http://www.aboutcookies.org/">www.aboutcookies.org</a>.</p>
                <p>Please remember that if you do choose to disable cookies, you may find that certain sections of our website do not work properly.</p>
                <p><span className='block text-2xl mt-3 mb-1'><strong>Do we track whether users open our emails?</strong></span>
                    Our emails may contain a single, campaign-unique “pixel” to tell us whether our emails are opened and verify any clicks through to links or advertisements within the email. We may use this information for purposes including determining which of our emails are more interesting to users, to query whether users who do not open our emails wish to continue receiving them and to inform our advertisers in aggregate how many users have clicked on their advertisements. The pixel will be deleted when you delete the email.</p>
                <p><strong><span className='block text-2xl mt-3 mb-1'>Analytics</span></strong>
                    The {CompanyName} website uses Google Analytics as its primary solution for tracking website visits, interactions and events. {CompanyName} uses website analytics data to help improve the way we delivery content and online experiences to visitors.</p>
                <p>For full details on how Google processes your data please visit:&nbsp;<a href="https://support.google.com/analytics/topic/2919631?hl=en&amp;ref_topic=1008008" target="blank">https://support.google.com/analytics/topic/2919631?hl=en&amp;ref_topic=1008008</a></p>
                <p><span className='block text-2xl mt-3 mb-1'><strong>Links to other websites&nbsp;</strong></span>
                    This privacy notice does not cover the links within this site linking to other websites. Those sites are not governed by this privacy policy, and if you have questions about how a site uses your information, you’ll need to check that site’s privacy statement.</p>
                <p><span className='block text-2xl mt-3 mb-1'><strong>How we keep your personal data secure</strong></span>
                    {CompanyName}’s security policies, rules and technical measures have been implemented to protect your personal information from unauthorised or improper use and from accidental loss.<br />
                    All our employees and data processors that have access to, and are associated with, the processing of your personal information are legally obliged to respect the confidentiality of your data.<br />
                    Note that our website provides commenting facilities where you can post information. When you do so, be aware that other visitors may collect any information you make public.</p>

                <p><span className='block text-2xl mt-3 mb-1'><strong>Who we share your personal data with</strong></span>
                    {CompanyName} has a variety of carefully selected companies that we share data with in order to carry out the activities described in the section How we use your data. These companies include but are not limited to Google and Stripe. These companies are not governed by this privacy policy, and if you have questions about how they use your information, you’ll need to check their privacy statement. <br /><br />

                    Our service uses YouTube API Services. By using our service, you agree to the terms of service. For more details, please review YouTube's Terms of Service at the following URL: <a href="https://www.youtube.com/t/terms" className='text-blue-500 hover:cursor-pointer hover:underline ml-1'>https://www.youtube.com/t/terms</a><br /><br />


                    Aditionally our use of YouTube API Services subjects you to Google's Privacy Policy. Therfore by using our service and agreeing to our privacy and terms policies, you also concent to Google's Privacy Policy. For more information, please review Google's Privacy Policy at the following URL: <a href="http://www.google.com/policies/privacy" className='text-blue-500 hover:cursor-pointer hover:underline ml-1'>http://www.google.com/policies/privacy</a><br /><br />

                </p>

                <p><span className='block text-2xl mt-3 mb-1'><strong>How long we keep your personal data for</strong></span>
                    We will hold your personal information on our systems only for as long as required to provide you with the products and/or services you have requested.</p>
                <p>Where you sign up to receive email marketing from us we will retain your e-mail address after you ‘opt-out’ of receiving emails in order to ensure that we continue to honour and respect that request. To unsubscribe from marketing emails at any time, please click on the unsubscribe link at the bottom of any marketing email. You may also contact us at <a href="mailto:{InfoContact}">{InfoContact}</a> to inform us if you do not wish to receive any marketing materials from us.</p>
                <p>In some circumstances you can ask us to delete your data: see “Your rights” below for further information.</p>
                <p>In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>
                <p><span className='block text-2xl mt-3 mb-1'><strong>Personal Data – Your rights</strong></span>
                    Under the&nbsp;GDPR&nbsp;&nbsp;including any implementing data protection &nbsp;legislation in the UK you have the rights shown below:-</p>
                <ol className='ml-3'>
                    <li>Your right to be informed</li>
                    <li>Your right of access</li>
                    <li>Your right to rectification</li>
                    <li>Your right to erasure</li>
                    <li>Your right to restrict processing</li>
                    <li>Your right to data portability</li>
                    <li>Your right to object</li>
                    <li>Your rights in relation to automated decision making and profiling.</li>
                </ol>
                <p><strong>Right of Access</strong><br />
                    You may, at any time, request access to the personal data that we hold which relates to you (you may have heard of this right being described as a&nbsp;<strong>“subject access request”</strong>).</p>
                <p>You can exercise this right at any time by writing to our Privacy Officer at <a href="mailto:{InfoContact}">{InfoContact}</a> and telling us that you are making a subject access request. You do not have to fill in a specific form to make this kind of request.</p>
                <p><strong>Your Right to Rectification and Erasure</strong><br />
                    You may, at any time, request that we correct personal data that we hold about you which you believe is incorrect or inaccurate. You may also ask us to erase personal data if you do not believe that we need to continue retaining it (you may have heard of this right described as the “right to be forgotten”).</p>
                <p>Please note that we may ask you to verify any new data that you provide to us and may take our own steps to check that the new data you have supplied us with is right. Further, we are not always obliged to erase personal data when asked to do so; if for any reason we believe that we have a good legal reason to continue processing personal data that you ask us to erase we will tell you what that reason is at the time we respond to your request.</p>
                <p>You can exercise this right at any time by writing to our Privacy Officer at <a href="mailto:{InfoContact}">{InfoContact}</a> and telling us that you are making a request to have your personal data rectified or erased and on what basis you are making that request. If you want us to replace inaccurate data with new data, you should tell us what that new data is. You do not have to fill in a specific form to make this kind of request.</p>
                <p><strong>Your Right to Restrict Processing</strong><br />
                    Where we process your personal data on the basis of a legitimate interest (see the How we use your data section of this Policy which explains why we use your personal data) you are entitled to ask us to stop processing it in that way if you feel that our continuing to do so impacts on your fundamental rights and freedoms or if you feel that those legitimate interests are not valid.</p>
                <p>You may also ask us to stop processing your personal data (a) if you dispute the accuracy of that personal data and want us to verify that data’s accuracy; (b) where it has been established that our use of the data is unlawful but you do not want us to erase it; (c) where we no longer need to process your personal data (and would otherwise dispose of it) but you wish for us to continue storing it in order to enable you to establish, exercise or defend legal claims.</p>
                <p>Please note that if for any reason we believe that we have a good legal reason to continue processing personal data that you ask us to stop processing, we will tell you what that reason is, either at the time we first respond to your request or after we have had the opportunity to consider and investigate it.</p>
                <p>You can exercise this right at any time by writing to our Privacy Officer at <a href="mailto:{InfoContact}">{InfoContact}</a> and telling us that you are making a request to have us stop processing the relevant aspect of your personal data and describing which of the above conditions you believe is relevant to that request. You do not have to fill in a specific form to make this kind of request.</p>
                <p><strong>Your Right to Portability</strong><br />
                    Where you wish to transfer certain personal data that we hold about you, which is processed by automated means, to a third party you may write to us and ask us to provide it to you in a commonly used machine-readable format.</p>
                <p>Because of the kind of work that we do and the systems that we use, we do not envisage this right being particularly relevant to the majority of individuals with whom we interact. However, if you wish to transfer your data from us to a third party we are happy to consider such requests.</p>
                <p><strong>Your Right to stop receiving communications</strong><br />
                    For details on your rights to ask us to stop sending you various kinds of communications,&nbsp;please contact our Privacy Officer at <a href="mailto:{InfoContact}">{InfoContact}</a></p>
                <p><strong>Your Right to object to automated decision making and profiling</strong><br />
                    You have the right to be informed about the existence of any automated decision making and profiling of your personal data, and where appropriate, be provided with meaningful information about the logic involved, as well as the significance and the envisaged consequences of such processing that affects you.</p>
                <p><strong>Exercising your rights</strong><br />
                    If you wish to exercise any of these rights &nbsp;or simply wish to find out what information, if any, we hold that relates to you, you must make your request in writing by sending an email to our Privacy Officer at <a href="mailto:{TeamContact}">{TeamContact}</a>.</p>
                <p>You can help us to process your request quickly by providing us with information that will help us identify records we hold that relate to you. For example your name, username, email address, telephone number or in the case of information captured via cookies, the IP address of the device you have used.</p>
                <p>If we decide to change our privacy policy &nbsp;we will post these changes on this page so that you are always aware of what information we collect, how we use it and in what circumstances we disclose it.</p>
                <p><span className='block text-2xl mt-3 mb-1'><strong>Changes to our privacy &amp; cookies policy&nbsp;</strong></span>
                    We keep our privacy &amp; cookies policy under regular review. This privacy &amp; cookies policy was last updated on 26 May 2024.</p>
            </div>
        </div>
    )
}
export function PrivateComponentTerms() {
    const SiteURL = process.env.REACT_APP_SITE_URL
    const CompanyName = process.env.REACT_APP_COMPANY_NAME
    const CompanyNumber = process.env.REACT_APP_COMPANY_NUMBER

    return (
        <div className={`
		flex flex-col md:w-10/12 w-12/12 m-auto p-1 px-0 text-[9px] md:text-sm
		`}>
            <div className={`
			flex flex-row  w-full p-4 border-b px-1 pb-1 
			`}>
                <div className='ml-3 basis-10/12'>
                    <span className='text-4xl'>
                        Terms and Conditions
                    </span>
                </div>

            </div>

            <div className='pb-48 border-b'>
                <ol>
                    <li><strong>ABOUT US AND OUR SERVICE</strong></li>
                </ol>

                <p className='ml-1 mt-1'> 1.1. {CompanyName} Ltd (company number {CompanyNumber}, “{CompanyName} ”, “our”, “we”, “the Company”) is a company registered in England and Wales.</p>
                <p className='ml-1 mt-1'>1.2. We operate the website {SiteURL} related subdomains (referred to collectively here as “the Site”). Our provision, management and administration of the Site and of any content included therein, including the provision of content and other services to members (as defined in clause 5 below), are referred to in these terms as “the Services”.</p>
                <p className='ml-1 mt-1'>1.3. To contact us, please email our customer support team via the Site.</p>
                <ol start={2}>
                    <li><strong>ABOUT THESE TERMS OF SERVICE</strong></li>
                </ol>
                <p className='ml-1 mt-1'>2.1. Please read these terms of service (“the Terms of Service”) carefully before participating in, accessing or using any of the Services. The Terms of Service apply to the Services. By using the Services you acknowledge that you have read the Terms of Service and agree that the Terms of Service constitute a binding legal agreement between the user (“you”) and {CompanyName}. If you do not agree with the Terms of Service, you must not use the Services.</p>
                <p className='ml-1 mt-1'>2.2. Please read our privacy policy (“the Privacy Policy”) which you can find <a href={`${SiteURL}/privacy/`}>here</a>. By using the Services you acknowledge that you have read the Privacy Policy and agree to its terms. If you do not agree with the Privacy Policy, you must not use the Services.</p>
                <p className='ml-1 mt-1'><strong>2.3. Your attention is particularly drawn to clauses 4.1 to 4.8 (warnings regarding scope of the Services).</strong></p>
                <p className='ml-1 mt-1'>2.4. The Terms of Service and any dispute arising out of or in connection with the Terms of Service are governed by the laws of England and are subject to the exclusive jurisdiction of the courts of England.</p>
                <ol start={3}>
                    <li><strong>AGE RESTRICTION</strong></li>
                </ol>
                <p className='ml-1 mt-1'>3.1. If you are under 13:</p>
                <p className='ml-1 mt-1'>3.1.1. please do not use the Services; and</p>
                <p className='ml-1 mt-1'>3.1.2. please do not attempt to register for membership (see clause 5 below) or send any information about yourself to the Company, including your name, address, telephone number, or email address.</p>
                <p className='ml-1 mt-1'>3.2. If it comes to our attention that we have unknowingly gathered information from a person under the age of 13, we will delete that information immediately.</p>
                <ol start={4}>
                    <li><strong>HOW TO USE THE SERVICES</strong></li>
                </ol>
                <p className='ml-1 mt-1'><strong>Warnings regarding the scope of the Services</strong></p>
                <p className='ml-1 mt-1'>4.1. The Services, including the content of the Site, are intended for educational and entertainment purposes only. The Services are not intended to, and do not, constitute legal, professional, medical, psychological, therapeutic or healthcare advice or diagnosis or counselling, and may not be used for such purposes.</p>
                <p className='ml-1 mt-1'>4.2. {CompanyName} does not represent or warrant or otherwise agree that the Services will be uninterrupted, error-free, reliable or timely, that defects will be corrected, or that the Services or any relevant server are free of viruses or other harmful components.</p>
                <p className='ml-1 mt-1'>4.3. {CompanyName} does not guarantee that videos or files available for viewing through the Site will be free of contaminating or damaging code such as viruses, trap doors and the like.</p>
                <p className='ml-1 mt-1'>4.4. {CompanyName} does not endorse content, nor warrant the accuracy, completeness, correctness, timeliness or usefulness of any opinions, advice, content, services, or merchandise provided through the Site or on the internet generally.</p>
                <p className='ml-1 mt-1'><strong>4.5. {CompanyName} does not make or provide any guarantee, representation, warranty or any other agreement of any kind, whether express or implied, whether written or oral, that the information and/or any services provided on or through the Site are valid, accurate, correct, complete, exhaustive of any given subject matter or fit for any particular purpose. It is extremely important that you check the information provided and requirements published by any relevant exam board(s) and school in order to ensure the accuracy, validity, completeness, exhaustiveness and fitness for any particular purpose of the information appearing on or any services provided through the Site. You rely on all and any such content and/or services strictly at your own risk.</strong></p>
                <p className='ml-1 mt-1'><strong>4.6. Any answers or solutions provided on the Site (including model answers or videos) constitute {CompanyName}’s interpretation of an answer and/or solution to a given question. However, those answers and/or solutions are not and are not held out as being the correct or definitive answer to that question. There may be scope for a range of other answers or solutions to that question.</strong></p>
                <p className='ml-1 mt-1'><strong>4.7. {CompanyName} is not liable or responsible in any way if you (or your student(s) or child/children where relevant) incorrectly answer a question in an assignment, exam or any form of formal testing administered by a school or exam board by using a method or procedure featured on the Site, including methods or procedures which do not correspond to the methods or procedures taught at the relevant student’s school (or equivalent educational facility).</strong></p>
                <p className='ml-1 mt-1'><strong>4.8. {CompanyName} does not in any way guarantee any outcome in formal or informal academic testing.</strong></p>
                <p className='ml-1 mt-1'><strong>Restrictions on use</strong></p>
                <p className='ml-1 mt-1'>4.9. When using the Site and the Services you agree not to:</p>
                <p className='ml-1 mt-1'>4.9.1. Modify, download, reproduce, copy or resell any material which is featured on the Site, the Site’s content or any other service offered by {CompanyName}, exemptions apply to specific account types educators (e.g Private Tutors) and small organisations.</p>
                <p className='ml-1 mt-1'>4.9.2. Commercially use the Services, the Site, the content or any portion derivative thereof, exemptions apply to specific account types educators (e.g Private Tutors) and small organisations (e.g Tuition Centers).</p>
                <p className='ml-1 mt-1'>4.9.3. Bypass any measure put in place to prevent or restrict access to any portion of the Site or any other service operated, owned and managed by {CompanyName}.</p>
                <p className='ml-1 mt-1'>4.9.4. Interfere with or damage in any way the operation of the Site, by any means, including uploading viruses, adware, spyware, worms, or other malicious code.</p>
                <p className='ml-1 mt-1'>4.9.5. Use any robot, spider, data miner, crawler, scraper or other automated means to access the Site to collect information from or otherwise interact with the Site.</p>
                <p className='ml-1 mt-1'>4.9.6. Reverse engineer, dissemble, decompile or otherwise attempt to discover the source code or underlying algorithms of all or any part of the Site, {CompanyName}’s Facebook page or any related webhosted service administered, managed and owned by {CompanyName}. The sole exception to this prohibition is where such activity is expressly permitted by applicable law.</p>
                <p className='ml-1 mt-1'>4.9.7. Use the Site, Facebook or any other related service unlawfully, which could damage, disable or impair the Site.</p>
                <p className='ml-1 mt-1'>4.9.8. Use the Site for any illegal purpose, or in violation of any local, state, national, or international law, including, without limitation, laws governing intellectual property and other proprietary rights, and data protection and privacy.</p>
                <p className='ml-1 mt-1'>4.9.9. Collect contact information from the Site, Facebook or any other related service administered, managed and owned by {CompanyName} for the purpose of sending emails, unwarranted messages or for the benefit of any third party.</p>
                <p className='ml-1 mt-1'>4.9.10. Modify or create derivatives of any part of the Site, Facebook or any other service administered, managed and owned by {CompanyName}.</p>
                <p className='ml-1 mt-1'>4.9.11. Post, upload or distribute any defamatory, slanderous or inaccurate user content or any other form of content.</p>
                <p className='ml-1 mt-1'>4.9.12. Post, upload or distribute any user content that is unlawful, offensive, pornographic, harassing, threatening, racist, hateful or otherwise inappropriate.</p>
                <p className='ml-1 mt-1'>4.9.13. Impersonate any person or entity, falsely state or otherwise misrepresent yourself and your age, or create a false identity on the Site, {CompanyName}’s Facebook page or any other service.</p>
                <p className='ml-1 mt-1'>4.9.14. Use or attempt to use another member’s account. Under no circumstances will this be permitted.</p>
                <p className='ml-1 mt-1'>4.9.14A.&nbsp; Log into the Site on more than one device at any given time.</p>
                <p className='ml-1 mt-1'>4.9.15. Delete, remove or obscure any copyright or other proprietary notices on the Site, Facebook or any other service.</p>
                <p className='ml-1 mt-1'>4.9.16. Upload, post, transmit or otherwise make available any unsolicited offers, advertisements, proposals or send junk mail or spam to other users of the Site. This includes, but is not limited to, unsolicited advertising, promotional materials, or other solicitation material, bulk mailing of commercial advertising, chain mail, informational announcements, charity requests, and petitions for signatures.</p>
                <p className='ml-1 mt-1'>4.9.17. Upload, post, transmit, share, store or otherwise make publicly available any private information of any third party, including addresses, phone number, email addresses, credit card numbers etc.</p>
                <p className='ml-1 mt-1'>4.9.18. Solicit personal information from anyone under 18 or solicit passwords or personal information for commercial or unlawful purposes.</p>
                <p className='ml-1 mt-1'>4.9.19. Upload, post, transmit, share or otherwise make available any material that contains software viruses or any other computer code, viruses, malware, bots, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment.</p>
                <p className='ml-1 mt-1'>4.9.20. Upload, post, transmit, share, store or otherwise make available content that would constitute, encourage or provide instructions for a criminal offence, violate the rights of any party, or that would otherwise create liability or violate any local, state, national or international law.</p>
                <p className='ml-1 mt-1'>4.9.21. Upload, post, transmit, share, store or otherwise make available content that, in the sole judgment of {CompanyName}, is objectionable or which restricts or inhibits any other person from using or enjoying the Site, or {CompanyName}’s Facebook page, or which may expose {CompanyName} or its users to any harm or liability of any type.</p>
                <ol start={5}>
                    <li><strong>MEMBERSHIP</strong></li>
                </ol>
                <p className='ml-1 mt-1'><strong>Registration</strong></p>
                <p className='ml-1 mt-1'>5.1. A certain percentage of the content on the Site is available for free viewing without requiring any form of membership.</p>
                <p className='ml-1 mt-1'>5.2. To access additional content you will be required to register as a member (“Member”) with the Site and to purchase a membership (“Membership”). {CompanyName} grants Members and Member-Organisations a licence to access certain content and features on/of the Site, depending on the precise terms of their Membership (see below).</p>
                <p className='ml-1 mt-1'>5.3. In order to register you will be required to provide certain information, including your full name and email address, and where applicable, we may require organisation name and details.</p>
                <p className='ml-1 mt-1'>5.4. The information you provide must be true, accurate, current and complete. If the original information provided upon initial sign-up changes after this time, it is your obligation to update and correct it. If any information provided by you is not true, accurate, current or complete, {CompanyName} maintains the right to terminate your Membership and refuse any current or future access or use of the Site.</p>
                <p className='ml-1 mt-1'>5.5. When selecting a password, for your own security you should choose a combination of lower case, uppercase, number and symbols (for example, Br1dGe$). Please ensure you select a password unique for your {CompanyName} account. Avoid choosing obvious words or dates such as a nickname or your birth date. Please exercise maximum caution to keep your username and password confidential and log-off from Site when your session is complete to prevent unauthorised access to your information. If your username or password is subject to unauthorised access, you should immediately inform {CompanyName}.</p>
                <p className='ml-1 mt-1'>5.6. Once {CompanyName} has received your information you will be sent a confirmation email confirming that you are a Member of the Site. You will have your own Member’s account, with an account management page, which will contain details of any Membership(s) that you have purchased and your account settings.</p>
                <p className='ml-1 mt-1'>5.7. For security purposes, each Member’s account can only be accessed on one device at a time. As explained in clause 5.15.2 below, we are able to monitor all Members’ usage of the Site and we do not permit Members to share their login details.&nbsp; If you have been logged out of your account, please check that you are not logged in on any other device and that you have not shared your login details with anyone else.</p>
                <p className='ml-1 mt-1'>5.7A. You can access the Site from a maximum of five devices within a 30-day period.&nbsp; If you exceed this number, your account may be terminated.</p>
                <p className='ml-1 mt-1'><strong>Charges and Payment</strong></p>
                <p className='ml-1 mt-1'>5.8. To access the Members’ section of {CompanyName}, a user must purchase Membership (“Subscription(s)”).</p>
                <p className='ml-1 mt-1'>5.9. {CompanyName} reserves the right to make any changes at any time to the amount, structure or timing of any charges and/or payments for any of the Services.</p>
                <p className='ml-1 mt-1'>5.10. You will be billed in advance on a recurring and periodic basis (except if your account is an independant student's account) (referred to as a “Billing Cycle”). Billing Cycles are set either on a monthly, three-monthly, annual or biennial basis, depending on the type of subscription plan you select when purchasing a Subscription.</p>
                <p className='ml-1 mt-1'>5.11. Where applicable, at the end of each Billing Cycle, your Subscription will automatically renew under the same conditions unless you cancel it or unless {CompanyName} cancels it. You may cancel your Subscription renewal either through your account management page or by contacting {CompanyName}’s customer support team.</p>
                <p className='ml-1 mt-1'>5.12. Where applicable, a valid payment method, including credit card or debit card, is required to process the payment for your Subscription. You shall provide {CompanyName} with accurate and complete billing information including your full name and details of a valid payment method. By submitting such payment information, you automatically authorise {CompanyName} to charge all Subscription fees incurred through your account to any such payment instruments.</p>
                <p className='ml-1 mt-1'><strong>Termination and suspension</strong></p>
                <p className='ml-1 mt-1'>5.15. {CompanyName} may terminate or suspend with immediate effect and without notice your access to and use of the Services as a Member, for any reason, and, in particular if we:</p>
                <p className='ml-1 mt-1'>5.15.1. do not receive payment;</p>
                <p className='ml-1 mt-1'>5.15.2. believe you have shared your account with another student, person or entity <strong>({CompanyName} is able to monitor your usage of the service and will use this information to draw a conclusion as to whether you have shared your account details);</strong></p>
                <p className='ml-1 mt-1'>5.15.2A. believe that you have logged into the Site on more than five devices within a given 30-day period;</p>
                <p className='ml-1 mt-1'>5.15.3. believe that you have breached any of the Terms of Service;</p>
                <p className='ml-1 mt-1'>5.15.4. are unable to verify the accuracy or validity of any information provided by you; or</p>
                <p className='ml-1 mt-1'>5.15.5. suspect fraudulent, abusive or illegal activity by you.</p>
                <p className='ml-1 mt-1'>5.16. {CompanyName} may come to a conclusion which will result in termination or suspension even if it is based upon our opinion or mere suspicion or belief, without any duty to prove that our opinion or suspicion is well-founded and even if our opinion or suspicion proves not to be well-founded.</p>
                <p className='ml-1 mt-1'>5.17. At any point, if {CompanyName} deems user conduct to be unsuitable, the Company may, of its own volition, choose to terminate any licence or permission previously granted to a user.</p>
                <p className='ml-1 mt-1'>5.18. You may cancel your Membership at any time but under no circumstances will {CompanyName} provide a refund of any payment(s) (see clause 12 below).</p>
                <ol start={6}>
                    <li><strong>THIRD PARTIES</strong></li>
                </ol>
                <p className='ml-1 mt-1'>6.1. {CompanyName} is not associated with and is not an agent of any third party, including any party named or linked on the Site, and therefore does not have any authority as regards such third parties.</p>
                <p className='ml-1 mt-1'>6.2. {CompanyName} maintains no jurisdiction over, endorsement, responsibility or liability for any content, products, advertising or other resources available from any third party.</p>
                <p className='ml-1 mt-1'>6.3. By agreeing to the Terms of Service, you agree that {CompanyName} (and our officers, directors and employees) have no liability to you in relation to any dispute you may have with any third party.</p>
                <p className='ml-1 mt-1'>6.4. {CompanyName} is a private company and acts solely and entirely independently from any exam board, including any exam board mentioned on the Site. {CompanyName} and the exam boards (whether or not mentioned on the Site) share no formal contract, affiliation, endorsement or association with each other. In no way do any of the exam boards manage, check or engage in any quality control in respect of any of the Services, including any the content available on the Site. As explained in clause 4 above, reliance on the Services, including any information appearing on the Site, is strictly at your own risk.</p>
                <ol start={7}>
                    <li><strong>COPYRIGHT</strong></li>
                </ol>
                <p className='ml-1 mt-1'>7.1. By agreeing to the Terms of Service, you acknowledge that all of the content and information provided on the Site and/or through the Services, or contained in any advertisements, sponsorships, or presented to you by {CompanyName}, is protected by relevant copyrights, trademarks, service marks, patents, or other proprietary rights and laws. This applies to all the content and information, including but not limited to text, software, music, sound, photographs, graphics, video or other material featured on the Site.</p>
                <p className='ml-1 mt-1'>7.2. Except as expressly authorised by {CompanyName}, you agree not to copy, reproduce, download, modify, translate, publish, broadcast, transmit, distribute, publish, edit, adapt, upload, share, display, license, sell or otherwise exploit for any purposes whatsoever any content featured on the Site.</p>
                <p className='ml-1 mt-1'>7.3. {CompanyName} reserves all relevant rights to the content on the Site not expressly granted in the Terms of Service.</p>
                <ol start={8}>
                    <li><strong>Limitation of Liabilities</strong></li>
                </ol>
                <p className='ml-1 mt-1'>8.1. To the fullest extent permitted by the applicable laws {CompanyName}, on its own behalf and on behalf of its parents, subsidiaries, affiliates, officers, directors, shareholders, employees and suppliers, exclude and disclaim liability for any losses and expenses of whatever nature and howsoever arising including, without limitation, any direct, indirect, special, incidental, consequential, punitive or exemplary damages, including but not limited to loss of business, profits, data, use, revenue or other economic advantage, claims of third parties, arising out of or in connection with the Services, or any linked services or websites, even if advised of the possibility of such damages. This limitation of liability applies whether the alleged liability is based on contract, tort (including negligence) strict liability or any other basis.</p>
                <p className='ml-1 mt-1'>8.2. The limitations set out in clause 8.1 above are fundamental to the bargain between us and you. The Services would not be provided without such limitations.</p>
                <p className='ml-1 mt-1'>8.3. {CompanyName}’s liability, and/or the liability of our parents, subsidiaries, officers, directors, employees, and suppliers, to you or any third parties, will not, in any circumstances, exceed the amount of any charges and/or payments that you make to {CompanyName} in the 12 months prior to the action or event giving rise to the liability.</p>
                <p className='ml-1 mt-1'>8.4. You and {CompanyName} agree that any cause of action arising out of or related to the Services must commence within 6 months after the cause of action arose (except for causes of action arising out of users’ responsibilities described in clause 10 below); otherwise, such cause of action is permanently barred.</p>
                <ol start={9}>
                    <li><strong>AMENDMENT OF THE TERMS OF SERVICE</strong></li>
                </ol>
                <p className='ml-1 mt-1'>9.1. {CompanyName} reserves the right, at our discretion, to change, modify, add or remove the whole or part of the Terms of Service at any time.</p>
                <p className='ml-1 mt-1'>9.2. In the event that the Terms of Service are altered, {CompanyName} will post the amended terms on the Site and changes will apply from the date of posting.</p>
                <p className='ml-1 mt-1'>9.3. If, after the Terms of Service and/or the Privacy Policy have been altered, you do not agree to their terms, you must stop using the Site.</p>
                <ol start={10}>
                    <li><strong>SITE MAINTENANCE AND ALTERATION</strong></li>
                </ol>
                <p className='ml-1 mt-1'>10.1. Whilst {CompanyName} will try to provide you with uninterrupted access to the Site and the Services, we may need to withdraw, modify, discontinue or temporarily or permanently suspend one or more aspects of the Site, e.g. if {CompanyName} is experiencing technical difficulties. However, {CompanyName} will try, wherever possible, to give reasonable notice of its intention to do so.</p>
                <p className='ml-1 mt-1'>10.2. {CompanyName} reserves the right to withhold, remove, alter, amend and/or discard any content on the Site, including content made available to you as a Member, for any reason with or without notice at any time.</p>
                <ol start={11}>
                    <li><strong>INDEMNITY</strong></li>
                </ol>
                <p className='ml-1 mt-1'>You agree to indemnify {CompanyName}, its subsidiaries, and affiliates, and their respective officers, agents, partners and employees, in relation to any loss, liability, claim, or demand, including reasonable legal fees, arising out of or in connection with your use of the Site and the Services in violation of the Terms of Service and/or arising from a breach of the Terms of Service and/or any breach of any representations and warranties that you make to the Company and/or if any content that you post on or through the Site causes {CompanyName}, its subsidiaries, and affiliates, and their respective officers, agents, partners and employees, to become liable to any other party.</p>
                <ol start={12}>
                    <li><strong>REFUNDS</strong></li>
                </ol>
                <p className='ml-1 mt-1'>We do not offer refunds on purchases made through the Site. We provide free samples of content before you purchase a Subscription or make a One-time payment in order to help inform your decision to purchase or not.</p>


                <ol start={12}>
                    <li>
                        <strong>Compliance with YouTube's Terms of Service</strong>
                    </li>
                </ol>
                <p className='ml-1 mt-1'>
                    By using our service, you acknowledge and agree that you are also bound by YouTube's Terms of Service. We require all users to review and comply with these terms when accessing YouTube content through our platform. <br />
                    YouTube's Terms of Service can be found at the following URL:
                    <a href='https://www.youtube.com/t/terms' className='text-blue-500 hover:cursor-pointer hover:underline ml-1'>https://www.youtube.com/t/terms</a>
                </p>
            </div>
        </div>
    )
}
function PrivateComponentLogin() {
    // global states and disptach
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch<AppDispatch>();
    //
    let [termsModal, setTermsModal] = useState(false)
    let [privacyModal, setPrivacyModal] = useState(false)
    const activeSubComponent = user.activeSubComponent
    if (activeSubComponent !== 'PrivateComponentLogin') {
        return (null)
    }
    const handlSubmitLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const usernameElement = form.elements.namedItem('username') as HTMLInputElement;
        const passwordElement = form.elements.namedItem('password') as HTMLInputElement;
        // Check if both elements are not null
        if (usernameElement.value && passwordElement.value) {
            dispatch(userLogin({
                userName: usernameElement.value,
                password: passwordElement.value
            }))
        } else {
            // Handle the case where elements could not be found
            toast.error(`Username or password field is missing.`);
            return null
        }
    }
    return (
        <div className={` flex flex-col text-slate-950`}>
            <div className={`
		    container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2
		    `}>
                <div className={`px-6 py-8 rounded shadow-md w-full
			bg-slate-200 text-slate-950 dark:bg-slate-900 dark:text-slate-100
			`}>
                    <h1 className="mb-2 text-xl text-center">Welcome</h1>
                    <h1 className="mb-4 text-sm text-center">Login to coursely.ai </h1>
                    <form onSubmit={handlSubmitLogin}>
                        <input
                            type="text" name="username" placeholder="Enter Username or Email"
                            className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                        />
                        <input
                            type='password' name='password' placeholder='Enter Password'
                            className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                        />
                        {user.loading?.userLogin === true ? (
                            <button
                                disabled
                                className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                            >
                                <TwLoading />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                            >Login</button>
                        )}
                    </form>
                    <div className="mt-3 text-slate-50 mb-3 m-auto text-center">
                        <span
                            onClick={() => { dispatch(setActiveSubComponent('PrivateComponentForgotPassword')) }}
                            className="
                                ml-1
                                text-blue-500
                                underline
                                hover:text-blue-400
                                hover:cursor-pointer
                            "
                        >

                            Forgot your password ?
                        </span>
                    </div>

                    <div className="text-center text-sm mt-4">
                        By
                        <span onClick={() => { dispatch(setActiveSubComponent('PrivateComponentSignUp')) }} className="
                            ml-1
                            text-blue-500
                            underline
                            hover:text-blue-400
                            hover:cursor-pointer
                            "
                        >
                            signing up
                        </span>
                        , you agree to the
                        <span
                            className={`
                                text-blue-500
                                underline
                                hover:text-blue-400
                                hover:cursor-pointer
                                ml-1
                            `}
                            onClick={() => setPrivacyModal(true)}
                        >Privacy Policy</span><br />
                        <span
                            className={`
                                text-blue-500
                                underline
                                hover:text-blue-400
                                hover:cursor-pointer
                            `}
                            onClick={() => setTermsModal(true)}
                        >Terms Of Service</span><br />
                        <Modal show={termsModal} onClose={() => setTermsModal(false)}>
                            <Modal.Header>Terms Of Service</Modal.Header>
                            <Modal.Body>
                                <PrivateComponentTerms />
                            </Modal.Body>
                            <Modal.Footer>
                                <TwButton
                                    className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                                    onClick={() => setTermsModal(false)}>
                                    Close
                                </TwButton>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={privacyModal} onClose={() => setPrivacyModal(false)}>
                            <Modal.Header>Privacy Policy</Modal.Header>
                            <Modal.Body>
                                <PrivateComponentPrivacy />
                            </Modal.Body>
                            <Modal.Footer>
                                <TwButton
                                    className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                                    onClick={() => setPrivacyModal(false)}>Close</TwButton>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>

            </div>
        </div>
    )
}
function PrivateComponentSignUp() {
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch<AppDispatch>();
    const captchaRef = useRef<CaptchaComponent>(null)
    //
    let [termsModal, setTermsModal] = useState(false)
    let [privacyModal, setPrivacyModal] = useState(false)
    const activeSubComponent = user.activeSubComponent
    const handlSubmitSignUp = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        // Retrieve all form elements
        const firstnameElement = form.elements.namedItem('firstname') as HTMLInputElement;
        const lastnameElement = form.elements.namedItem('lastname') as HTMLInputElement;
        const usernameElement = form.elements.namedItem('username') as HTMLInputElement;
        const emailElement = form.elements.namedItem('email') as HTMLInputElement;
        const passwordElement = form.elements.namedItem('password') as HTMLInputElement;
        const confirmPasswordElement = form.elements.namedItem('confirmpassword') as HTMLInputElement;
        //
        if (
            firstnameElement.value &&
            lastnameElement.value &&
            usernameElement.value &&
            emailElement.value &&
            passwordElement.value &&
            confirmPasswordElement.value &&
            captchaRef && captchaRef.current
        ) {
            const token = captchaRef.current.getValue();
            var guest_hid = null
            if (user.is_guest) {
                guest_hid = user.hid
            }
            dispatch(userSignUp({
                guest_hid: guest_hid!,
                firstName: firstnameElement.value,
                lastName: lastnameElement.value,
                userName: usernameElement.value,
                email: emailElement.value,
                password: passwordElement.value,
                confirmPassword: confirmPasswordElement.value,
                recaptchaToken: token,
            }))
            dispatch(userLogout())
        } else {
            // Handle the case where elements could not be found
            toast.error(`There is a missing field.`);
        }
    }

    if (activeSubComponent !== 'PrivateComponentSignUp') {
        return (null)
    }
    return (
        <div className={` flex flex-col text-slate-950`}>
            <div className={`
		    container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2
		    `}>
                <div className={`px-6 py-8 rounded shadow-md w-full
			bg-slate-200 text-slate-950 dark:bg-slate-900 dark:text-slate-100
			`}>
                    <h1 className="mb-2 text-xl text-center">Welcome</h1>
                    {user.is_guest ? (
                        <h1 className="mb-4 text-sm text-center">Sign up to save your progress and to unlock more features!</h1>
                    ) : (
                        <h1 className="mb-4 text-sm text-center">Sign up to coursely.ai </h1>
                    )}
                    <form onSubmit={handlSubmitSignUp}>
                        <input
                            type='text' name='firstname' placeholder='Enter first name'
                            className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                        />
                        <input
                            type='text' name='lastname' placeholder='Enter last name'
                            className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                        />
                        <input
                            type='text' name='username' placeholder='Enter username'
                            className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                        />
                        <input
                            type='text' name='email' placeholder='Enter email'
                            className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                        />
                        <input
                            type='password' name='password' placeholder='Enter Password'
                            className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                        />
                        <input
                            type='password' name='confirmpassword' placeholder='Confirm Password'
                            className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                        />
                        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} theme='dark' ref={captchaRef} className='m-auto' />
                        {user.loading.userSignUp === true ? (
                            <button
                                disabled
                                className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                            >
                                <TwLoading />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                            >SignUp</button>
                        )}
                    </form>
                    <div className="mt-4 mb-4 text-center">
                        Already have an account?
                        <span onClick={() => { dispatch(setActiveSubComponent('PrivateComponentLogin')) }} className="
				ml-1
				text-blue-500
				underline
				hover:text-blue-400
				hover:cursor-pointer
				">
                            Log in
                        </span>
                    </div>

                    <div className="text-center text-sm mt-4">
                        By signing up, you agree to the
                        <span className={`
				text-blue-500
				underline
				hover:text-blue-400
				hover:cursor-pointer
				ml-1
			`}
                            onClick={() => setPrivacyModal(true)}
                        >Privacy Policy</span><br />
                        <span className={`
				text-blue-500
				underline
				hover:text-blue-400
				hover:cursor-pointer
			`}
                            onClick={() => setTermsModal(true)}
                        >Terms Of Service</span><br />
                        <Modal show={termsModal} onClose={() => setTermsModal(false)}>
                            <Modal.Header>Terms Of Service</Modal.Header>
                            <Modal.Body>
                                <PrivateComponentTerms />
                            </Modal.Body>
                            <Modal.Footer>
                                <TwButton
                                    className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                                    onClick={() => setTermsModal(false)}>Close</TwButton>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={privacyModal} onClose={() => setPrivacyModal(false)}>
                            <Modal.Header>Privacy Policy</Modal.Header>
                            <Modal.Body>
                                <PrivateComponentPrivacy />
                            </Modal.Body>
                            <Modal.Footer>
                                <TwButton
                                    className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                                    onClick={() => setPrivacyModal(false)}>Close</TwButton>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>

            </div>
        </div>
    )
}
function PrivateComponentForgotPassword() {
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch<AppDispatch>();
    const captchaRef = useRef<CaptchaComponent>(null)
    //
    const activeSubComponent = user.activeSubComponent
    const handlSubmitForgotPassword = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const usernameElement = form.elements.namedItem('username') as HTMLInputElement;
        // Check if both elements are not null
        if (usernameElement.value && captchaRef && captchaRef.current) {
            const token = captchaRef.current.getValue();
            dispatch(userForgotPassword({
                userName: usernameElement.value,
                recaptchaToken: token
            }))
        } else {
            // Handle the case where elements could not be found
            toast.error(`Username or password field is missing.`);
        }
    }
    if (activeSubComponent !== 'PrivateComponentForgotPassword') {
        return (null)
    }
    return (
        <div className={` flex flex-col text-slate-950`}>
            <div className={`
		    container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2
		    `}>
                <div className={`px-6 py-8 rounded shadow-md w-full
			bg-slate-200 text-slate-950 dark:bg-slate-900 dark:text-slate-100
			`}>
                    <h1 className="mb-4 text-sm text-center">Sorry to hear the bad news, use this to reset your password.</h1>
                    <form onSubmit={handlSubmitForgotPassword}>
                        <input
                            type='text' name='username' placeholder='Enter Username or Email'
                            className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                        />
                        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} theme='dark' ref={captchaRef} className='m-auto' />
                        {user.loading.userForgotPassword ? (
                            <button
                                disabled
                                className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                            >
                                <TwLoading />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                            >Submit</button>
                        )}
                    </form>
                    <div className="mt-4 mb-4 text-center">
                        Already have an account ?
                        <span onClick={() => { dispatch(setActiveSubComponent('PrivateComponentLogin')) }} className="
                        ml-1
                        text-blue-500
                        underline
                        hover:text-blue-400
                        hover:cursor-pointer
                        ">
                            Log in
                        </span>
                    </div>

                    <div className="text-center text-sm mt-4">
                        Dont have an account ?
                        <span onClick={() => { dispatch(setActiveSubComponent('PrivateComponentSignUp')) }} className="
                            ml-1
                            text-blue-500
                            underline
                            hover:text-blue-400
                            hover:cursor-pointer
                            "
                        >
                            Sign up
                        </span>
                    </div>
                </div>

            </div>
        </div>
    )
}
function PrivateComponentPasswordReset() {
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch<AppDispatch>();
    //
    const activeSubComponent = user.activeSubComponent

    const handlSubmitPasswordReset = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const uidb64 = form.elements.namedItem('uidb64') as HTMLInputElement;
        const token = form.elements.namedItem('token') as HTMLInputElement;
        const password = form.elements.namedItem('password') as HTMLInputElement;
        const confirmPassword = form.elements.namedItem('confirmpassword') as HTMLInputElement;
        // Check if both elements are not null
        if (
            uidb64.value &&
            token.value &&
            password.value &&
            confirmPassword.value &&
            (password.value === confirmPassword.value)
        ) {
            dispatch(userResetPassword({
                uidb64: uidb64.value,
                token: token.value,
                password: password.value,
                confirmpassword: confirmPassword.value
            }))
        } else {
            // Handle the case where elements could not be found
            toast.error(`Something went wrong, please check all inputs.`);
        }
    }
    if (activeSubComponent !== 'PrivateComponentPasswordReset') {
        return (null)
    }
    return (
        <div className={` flex flex-col text-slate-950`}>
            <div className={`
		    container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2
		    `}>
                <div className={`px-6 py-8 rounded shadow-md w-full
			bg-slate-100
			`}>
                    <h1 className="mb-2 text-xl text-center">Welcome back!</h1>
                    <h1 className="mb-4 text-sm text-center">Account password reset for coursely.ai </h1>
                    <form onSubmit={handlSubmitPasswordReset}>
                        <input
                            type='hidden' name='uidb64' value={user.uidb64 ?? 'Unknown'}
                        />
                        <input
                            type='hidden' name='token' value={user.token ?? 'Unknown'}
                        />
                        <input
                            type='password' name='password' placeholder='New password'
                            className="block border border-grey-light w-full p-3 rounded mb-4"
                        />
                        <input
                            type='password' name='confirmpassword' placeholder='Confirm password'
                            className="block border border-grey-light w-full p-3 rounded mb-4"
                        />
                        {user.loading.userResetPassword ? (
                            <button
                                disabled
                                className="w-full text-center py-3 rounded bg-green-500 focus:outline-none my-1"
                            >
                                <TwLoading />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="w-full text-center py-3 rounded bg-green-500 focus:outline-none my-1"
                            >Reset Password</button>
                        )}
                    </form>
                </div>

            </div>
        </div>
    )
}
function PrivateComponentActivateAccount() {
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch<AppDispatch>();
    //
    const activeSubComponent = user.activeSubComponent

    const handlSubmitActivateAccount = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const uidb64 = form.elements.namedItem('uidb64') as HTMLInputElement;
        const token = form.elements.namedItem('token') as HTMLInputElement;
        // Check if both elements are not null
        if (
            uidb64.value &&
            token.value
        ) {
            dispatch(userActivation({
                uidb64: uidb64.value,
                token: token.value,
            }))
        } else {
            // Handle the case where elements could not be found
            toast.error(`Something went wrong, please check all inputs.`);
        }
    }
    if (activeSubComponent !== 'PrivateComponentActivateAccount') {
        return (null)
    }

    return (
        <div className={` flex flex-col text-slate-950`}>
            <div className={`
		    container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2
		    `}>
                <div className={`px-6 py-8 rounded shadow-md w-full
			bg-slate-100
			`}>
                    <h1 className="mb-2 text-xl text-center">Welcome back!</h1>
                    <h1 className="mb-4 text-sm text-center">Activation for coursely.ai </h1>
                    <form onSubmit={handlSubmitActivateAccount}>

                        <input
                            type='hidden' name='uidb64' value={user.uidb64 ?? 'Unknown'}
                        />
                        <input
                            type='hidden' name='token' value={user.token ?? 'Unknown'}
                        />
                        {user.loading.userActivation ? (
                            <button
                                disabled
                                className="w-full text-center py-3 rounded bg-green-500 focus:outline-none my-1"
                            >
                                <TwLoading />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="w-full text-center py-3 rounded bg-green-500 focus:outline-none my-1"
                            >Activate Account</button>
                        )}
                    </form>
                </div>

            </div>
        </div>
    )
}
function PrivateComponentPerformanceStatistics() {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (user.accessToken) {
            dispatch(courseGetPerformanceStatistics({ accessToken: user.accessToken }))
        }
    }, [dispatch, user.accessToken])

    const performanceChartsThemeObject = createTheme({
        palette: {
            mode: user.theme as 'light' | 'dark',
        },
    });

    let tracking_info: any = course.performanceStatistics ? course.performanceStatistics : {}
    let activity_keys = 'activity' in tracking_info ? Object.keys(tracking_info.activity).sort() : []

    const activity_array: any = [];
    activity_keys.forEach(key => {
        var match = key.match(/key_(\d{4})(\d{2})(\d{2})/)!;
        var formatted_key = `${match[1]}/${match[2]}/${match[3]}`;
        activity_array.push({ date: formatted_key, count: tracking_info?.activity[key] })
    })
    // Initialize an array with 365 elements, all set to 0

    // Function to calculate the day of the year for a given date string (format: 'YYYY/MM/DD')
    const getDayOfWeek = (dateString: string): number => {
        const date = new Date(dateString);
        const day = date.getDay();
        return day //sunday = 0, monday=1 ...
    };
    const currentYear = new Date().getFullYear();
    const offsetJanuaryFirst = getDayOfWeek(`${currentYear}-01-01`);

    function getDayOfYear(dateString: any) {
        const date = (new Date(dateString) as any);
        const start = (new Date(date.getFullYear(), 0, 0) as any);
        const diff = date - start;
        const oneDay = 1000 * 60 * 60 * 24;
        return Math.floor(diff / oneDay);
    }
    const todayDayOfYear = getDayOfYear(new Date());
    const days_of_year = Array(todayDayOfYear).fill(0);

    // Iterate over the activity_array and update the days_of_year array
    activity_array.forEach((entry: any) => {
        const dayOfYear = getDayOfYear(entry.date); // -1 because the array is zero-indexed
        days_of_year[dayOfYear + offsetJanuaryFirst] = entry.count;
    });



    return (
        <ThemeProvider theme={performanceChartsThemeObject}>
            <div className='flex flex-col justify-end max-w-[100%] overflow-scroll text-center border rounded-xl border-gray-950 dark:border-gray-50 p-2 mt-2'>
                <Heatmap
                    count={days_of_year}
                    squareNumber={todayDayOfYear + offsetJanuaryFirst}
                    squareGap='2px'
                    squareSize='10px'
                />
            </div>
        </ThemeProvider >
    )
}
export function SubComponentSubscriptionCourses() {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    const [guestModal, setGuestModal] = useState(false)

    useEffect(() => {
        if (
            'courseProgress' in course &&
            user.hid &&
            user.accessToken &&
            'updated' in course.courseProgress &&
            course.courseProgress.updated
        ) {
            dispatch(setCourseProgress({ ...course.courseProgress, updated: false }));
            dispatch(courseGetSubscriptionList({ accessToken: user.accessToken }));
            // Update the previous progress state
        }
    }, [dispatch, user.hid, user.accessToken, course.courseProgress, course]);

    useEffect(() => {
        if (
            user.hid &&
            user.accessToken
        ) {
            dispatch(courseGetSubscriptionList({ accessToken: user.accessToken }))
        }
    }, [dispatch, user.hid, user.accessToken]
    )
    return (
        course.loading.courseGetSubscriptionList && course.subscriptionCourses.length === 0 ? (
            <TwLoading className='mb-3' />
        ) : (
            < div className={`flex flex-wrap flex-row p-2 w-[95%] m-auto gap-1`}>
                <div
                    className={`
                                flex flex-row items-center rounded-lg 
                                transform transition-transform duration-250

                                p-1
                                cursor-pointer
                                text-center
                                justify-center
                                rounded-lg
                                border
                                border-dashed

                                dark:border-slate-500
                                dark:hover:border-slate-300

                                border-gray-400
                                hover:border-gray-900
                                w-[100%]
                                md:min-w-60
                                md:max-w-60
                                md:overflow-scroll
                                min-h-32
                                max-h-32
                            `}
                    onClick={() => {
                        dispatch(setnavTab('FindCourses'))
                    }}
                >
                    <div>
                        Add Pre-made Course
                    </div>
                </div>
                {!user.hid || course.subscriptionCourses.length > 0 ? (
                    course.subscriptionCourses.map((subscription, idx) => (
                        subscription.saved ? (
                            <TwCourseCardBasic
                                key={`subscriptioncourses_${idx}`}
                                className='p-1'
                                subscription={subscription}

                                hid={subscription.course.hid}
                                icon_url={subscription.course.icon_url}
                            >
                                {subscription.course.name}
                            </TwCourseCardBasic>
                        ) : (null)
                    ))
                ) : (null)}
                {guestModal ? (
                    <TwGuestLoginModal guestModal={guestModal} setGuestModal={setGuestModal} />
                ) : (null)}
            </div >
        )
    )
}
export function SubComponentTutorial() {
    const infinite_knowledge = { backgroundImage: "url('/tutorial/infinite_knowledge.jpeg')" };
    // State to hold the dynamic width
    // this should be hidden until after the polishing and model completion
    return (
        <>
            <Swiper
                freeMode={
                    {
                        enabled: true,
                        momentum: true,
                        momentumRatio: 0.1,
                        momentumVelocityRatio: 0.3,
                        sticky: true,
                    }
                }
                allowTouchMove={true}
                navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}
                modules={[FreeMode, Pagination, Navigation]}
                resistance={true}
                resistanceRatio={0.7}
                className={`mySwiper rounded-[40px] w-[95%] m-auto group mt-8 mb-8 !hidden`}
                style={{ height: '320px' }}
            >
                <SwiperSlide>
                    <div className={`bg-black w-full h-[100%]`}>
                        <div className={`w-full h-[100%] bg-cover bg-center opacity-30`} style={infinite_knowledge} ></div>
                        <div className='text-center z-10 absolute left-0 top-0 w-full h-full'>
                            <span className="text-white text-xl font-bold">Hello</span>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <Swiper
                        className="mySwiper2 swiper-v"
                        direction={'vertical'}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        style={{ height: '320px' }}
                    >
                        <SwiperSlide className='text-center'>
                            <div className={`bg-black w-full h-[100%]`}>
                                <div className={`w-full h-[100%] bg-cover bg-center opacity-30`} style={infinite_knowledge} ></div>
                                <div className='text-center z-10 absolute left-0 top-0 w-full h-full'>
                                    <span className="text-white text-xl font-bold">Hello</span>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className='text-center'>
                            <div className={`bg-black w-full h-[100%]`}>
                                <div className={`w-full h-[100%] bg-cover bg-center opacity-30`} style={infinite_knowledge} ></div>
                                <div className='text-center z-10 absolute left-0 top-0 w-full h-full'>
                                    <span className="text-white text-xl font-bold">Hello</span>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className='text-center'>
                            <div className={`bg-black w-full h-[100%]`}>
                                <div className={`w-full h-[100%] bg-cover bg-center opacity-30`} style={infinite_knowledge} ></div>
                                <div className='text-center z-10 absolute left-0 top-0 w-full h-full'>
                                    <span className="text-white text-xl font-bold">Hello</span>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={`bg-black w-full h-[100%]`}>
                        <div className={`w-full h-[100%] bg-cover bg-center opacity-30`} style={infinite_knowledge} ></div>
                        <div className='text-center z-10 absolute left-0 top-0 w-full h-full'>
                            <span className="text-white text-xl font-bold">Hello</span>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={`bg-black w-full h-[100%]`}>
                        <div className={`w-full h-[100%] bg-cover bg-center opacity-30`} style={infinite_knowledge} ></div>
                        <div className='text-center z-10 absolute left-0 top-0 w-full h-full'>
                            <span className="text-white text-xl font-bold">Hello</span>
                        </div>
                    </div>
                </SwiperSlide>

                <button className="arrow-right arrow absolute z-10 top-0 left-0 h-[100%] w-[5%] hidden group-hover:block">
                    <FontAwesomeIcon icon={faChevronLeft} size='2xl' className='text-slate-950 dark:text-slate-50' />
                    <div className="absolute bottom-0 left-0 top-0 bg-gradient-to-r to-transparent w-full from-slate-100 dark:from-slate-800"></div>
                </button>
                <button className="arrow-left arrow absolute z-10 top-0 right-0 h-[100%] w-[5%] hidden group-hover:block">
                    <FontAwesomeIcon icon={faChevronRight} size='2xl' className='text-slate-950 dark:text-slate-50' />
                    <div className="absolute bottom-0 right-0 top-0 bg-gradient-to-l to-transparent w-full from-slate-100 dark:from-slate-800"></div>
                </button>
            </Swiper >
        </>
    )
}
export function SubComponentCatagoryCoureses() {
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    const [openCatagoryModal, setOpenCatagoryModal] = useState(false)
    const [activeCatagory, setActiveCatagory] = useState('')
    //
    useEffect(() => {
        dispatch(courseGetCatagoryList())
    }, [dispatch]
    )
    useEffect(() => {
        if (course.catagories.length > 0) {
            dispatch(courseGetCatagoryCourses({ category: course.catagories[0].name }))
        }
    }, [dispatch, course.catagories]
    )
    const switchCatagory = (catagory_name: string | null = null) => {
        dispatch(courseGetCatagoryCourses({ category: catagory_name }))
    }
    const [inputValue, setInputValue] = useState('');
    const [searchModal, setSearchModal] = useState(false);
    //
    const handleKeyPress = (event: { key?: string, manual?: boolean }) => {
        if ((event.key === 'Enter' || event.manual) && inputValue !== '') {
            // Call your function here
            setSearchModal(true)
            dispatch(resetCourseSearchList())
            dispatch(courseGetCourseSearch({ searchString: String(inputValue) }))
        } else if ((event.key === 'Enter' || event.manual) && inputValue === '') {
            setSearchModal(true)
        }
    };
    return (
        <>
            <div className='flex flex-col basis-11/12 mt-8'>
                <span className='text-lg ml-10 '>
                    All Categories
                </span>
            </div>
            <div className="
                flex flex-row mb-3 md:max-w-96 m-auto relative m-0 min-w-0 flex-auto
                rounded-full border border-solid border-neutral-300 bg-transparent
                bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6]
                text-slate-700 outline-none transition duration-200 ease-in-out
                focus:z-[3] focus:border-primary focus:text-slate-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)]
                focus:outline-none dark:border-neutral-600 dark:text-slate-200 dark:placeholder:text-slate-200
                dark:focus:border-primary justify-end ml-auto w-[95%] mt-2 mb-2
                "
            >
                <div className='flex align-middle p-3 !pl-1 cursor-pointer'
                    onClick={() => handleKeyPress({ manual: true })}
                >
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
                <input
                    type="search"
                    className="m-0 w-full min-w-0 flex-auto bg-transparent bg-clip-padding text-base font-normal leading-[1.6] text-slate-700 outline-none transition duration-200 ease-in-out dark:text-slate-200 dark:placeholder:text-slate-200 border-0 focus:ring-0"
                    id="exampleSearch"
                    placeholder="Search for courses"
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyPress}
                    value={inputValue}
                />
            </div>
            <div className={`flex flex-wrap flex-row p-2 w-[95%] m-auto gap-1`}>
                {course.catagories.map((catagory, i) => (
                    <Card
                        key={`categories_cards_${i}`}
                        className={`
                            flex flex-row items-center rounded-lg 
                            transform transition-transform duration-250
                            relative

                            dark:bg-slate-700
                            dark:hover:bg-slate-500

                            bg-slate-100
                            hover:bg-slate-200
                            p-1
                            cursor-pointer
                            w-[100%]
                            md:min-w-60
                            md:max-w-60
                            md:overflow-scroll
                            min-h-32
                            max-h-32
                            `}
                        theme={
                            {
                                "root": {
                                    "children": "flex h-full flex-col justify-center gap-2 p-2",
                                    "horizontal": {
                                        "off": "flex-col",
                                        "on": "flex-col md:max-w-xl md:flex-row"
                                    },
                                    "href": "hover:bg-gray-100 dark:hover:bg-gray-700"
                                },
                                "img": {
                                    "base": "",
                                    "horizontal": {
                                        "off": "rounded-t-lg",
                                        "on": "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
                                    }
                                }
                            }
                        }
                        onClick={() => {
                            switchCatagory(catagory.name);
                            setOpenCatagoryModal(true);
                            setActiveCatagory(catagory.name)
                        }}
                    >
                        <div className="flex flex-row w-full text-start">
                            <div className="flex flex-row m-m-0 text-[14px] w-[85%]">
                                {catagory.name}
                            </div>
                        </div>
                        <div className="flex flex-row w-full text-[8px] text-gray-400">
                            <div className="flex flex-row m-m-0 mr-1">
                                <FontAwesomeIcon icon={faBook} className='mr-1' /> {catagory.category_display_info.n_courses} courses
                            </div>
                            <div className="flex flex-row m-m-0 mr-1">
                                <FontAwesomeIcon icon={faGraduationCap} className='mr-1' /> {catagory.category_display_info.n_students} Learners
                            </div>
                            <div className="flex flex-row m-m-0 mr-1">
                                <FontAwesomeIcon icon={faChartLine} className='mr-1' /> {catagory.category_display_info.n_chats} Interactions
                            </div>
                        </div>
                        <div className="flex flex-row w-full flex-wrap text-[8px]">
                            <div className='mr-1'>
                                Examples:
                            </div>
                            {catagory.category_display_info.top_courses_info.map((course_display_info, i) => (
                                <div key={`category_display_info_${i}`} className='mr-1'>
                                    | {course_display_info.name}
                                </div>
                            ))}...
                        </div>
                    </Card>
                ))}
            </div >
            <Modal
                show={openCatagoryModal}
                size={'4xl'}
                onClose={() => setOpenCatagoryModal(false)}
                theme={{ header: { title: "text-xl font-medium text-gray-900 dark:text-white basis-10/12" } }}
                position={'top-center'}
            >
                <Modal.Header>Courses in {activeCatagory}</Modal.Header>
                <Modal.Body>
                    <div className='h-[500px] min-h-[500px] max-h-[500px]'>
                        {course.loading.courseGetCatagoryCourses ? (
                            <div className='m-12'>
                                <TwLoading className='w-6 h-6' />
                            </div>
                        ) : (
                            <div className={`flex flex-wrap flex-row p-2 w-[95%] m-auto gap-1`}>
                                {course.catagoryCourses.map((course, i) => (
                                    <TwCourseCardBasicDisplay
                                        key={`basic_course_card_display_${i}`}
                                        numberSubscriptions={Number(course.n_subscriptions)}
                                        numberChats={String(course.total_n_chats)}
                                        hid={course.hid}
                                        lesson_number={course.default_lnum}
                                        icon_url={course.icon_url}
                                    >
                                        {course.name}
                                    </TwCourseCardBasicDisplay>
                                ))}
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal
                show={searchModal}
                size={'4xl'}
                onClose={() => setSearchModal(false)}
                theme={{ header: { title: "text-xl font-medium text-gray-900 dark:text-white basis-10/12" } }}
                position={'top-center'}
            >
                <Modal.Header>
                    <div className="flex flex-row min-w-[90%] m-auto relative m-0 min-w-0 flex-auto rounded-full border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-slate-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-slate-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-slate-200 dark:placeholder:text-slate-200 dark:focus:border-primary">
                        <div className='flex align-middle p-3 !pl-1 cursor-pointer'
                            onClick={() => handleKeyPress({ manual: true })}
                        >
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </div>
                        <input
                            type="search"
                            className="m-0 w-full min-w-0 flex-auto bg-transparent bg-clip-padding text-base font-normal leading-[1.6] text-slate-700 outline-none transition duration-200 ease-in-out dark:text-slate-200 dark:placeholder:text-slate-200 border-0 focus:ring-0"
                            id="exampleSearch"
                            placeholder="Search for courses"
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={handleKeyPress}
                            value={inputValue}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='h-[500px] min-h-[500px] max-h-[500px]'>
                        {course.loading.courseGetCourseSearch || course.loading.courseGetAllCatagoryCourses ? (
                            <div className='m-x-auto'>
                                <TwLoading className='w-6 h-6 mt-4' />
                            </div>
                        ) : (
                            <div className={`flex flex-wrap flex-row p-2 w-[95%] m-auto gap-1`}>
                                {course.courseSearchList?.length > 0 ? (
                                    course.courseSearchList.map((searchCourse, idx) => (
                                        <TwCourseCardBasicDisplay
                                            key={`search_course_${idx}`}
                                            numberSubscriptions={Number(searchCourse.n_subscriptions)}
                                            numberChats={String(searchCourse.total_n_chats)}
                                            hid={searchCourse.hid}
                                            lesson_number={searchCourse.default_lnum}
                                            icon_url={searchCourse.icon_url}
                                        >
                                            {searchCourse.name}
                                        </TwCourseCardBasicDisplay>
                                    ))
                                ) : (
                                    <>
                                        No results, please try again!
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

        </>
    )
}
export function SubComponentGeneralTutorHeader() {
    return (
        <div
            className={`
				overflow-scroll
				flex flex-col pl-1 pr-1 py-1
				transform transition-transform duration-250
				bg-slate-200
				dark:bg-slate-800
				`}
        >
            <div className='flex flex-row justify-center items-center h-full'>
                <div className="flex flex-col">
                    <div className='my-3 m-auto'>
                        <div className='px-2 text-center py-3 rounded bg-green-500 focus:outline-none my-1 hover:cursor-pointer'>
                            Create Lesson
                        </div>
                    </div>
                    <div className='m-auto'>
                        <Link to={`/generallhistory?rdo=True`} className={`
								p-3
								rounded-md
								inline-block w-fit
							`}>
                            <FontAwesomeIcon icon={faDumbbell} className='text-yellow-300' /> Revise
                        </Link>
                        <Link to={`/generalperformance?rdo=True`} className={`
								p-3
								rounded-md
								inline-block w-fit
							`}>
                            <FontAwesomeIcon icon={faChartPie} className='mr-1' /> Performance
                        </Link>
                        <Link to={`/generalsesettings?rdo=True`} className={`
								p-3
								rounded-md
								inline-block w-fit
							`}>
                            <FontAwesomeIcon icon={faGear} className='mr-1' /> Settings
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export function SubComponentChatInterface() {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    let navigate = useNavigate();
    //
    const [openIntroductionModal, setOpenIntroductionModal] = useState(false)
    const [videosModal, setVideosModal] = useState(false)
    const [chatModal, setChatModal] = useState(false)
    const [revisionModal, setRevisionModal] = useState(false)
    const [revisionResolution, setRevisionResolution] = useState('')
    //
    const { coursename, chapter_lnum, topic_lnum } = useParams();
    const lesson_number = `${chapter_lnum}/${topic_lnum}`
    //
    useEffect(() => {// updates the course lesson on user, course or lesson change
        if (user.hid && course.courseId && course.lessonNumber && user.accessToken) {
            if (
                coursename === course.courseId &&
                lesson_number === course.lessonNumber
            ) {
                dispatch(courseGetLesson({ cid: course.courseId, lnum: course.lessonNumber, accessToken: user.accessToken }))
            }
        }
    }, [
        dispatch,
        user.hid,
        course.courseId,
        course.lessonNumber,
        user.accessToken,
        coursename, lesson_number
    ])
    useEffect(() => {// updates the course lesson on user, course or lesson change
        if (user.hid && course.courseId && course.lessonNumber && user.accessToken) {
            if (
                (coursename !== course.courseId ||
                    lesson_number !== course.lessonNumber
                ) || typeof course.activeThread !== 'number'
            ) {
                dispatch(setActiveThread(0));
            }
        }
    }, [
        dispatch,
        user.hid,
        course.courseId,
        course.lessonNumber,
        course.activeThread,
        user.accessToken,
        coursename,
        lesson_number
    ])
    useEffect(() => {// updates the course lesson on user, course or lesson change
        if (course.courseId && course.lessonNumber) {
            if (
                coursename === course.courseId &&
                lesson_number === course.lessonNumber
            ) {
                dispatch(courseGetLessonIntroduction({ cid: course.courseId, lnum: course.lessonNumber }))
            }
        }
    }, [
        dispatch,
        user.hid,
        course.courseId,
        course.lessonNumber,
        user.accessToken,
        course.activeThread,
        coursename, lesson_number
    ])
    const FlowBiteTimeLineTheme = {
        "root": {
            "direction": {
                "horizontal": "items-base sm:flex",
                "vertical": "relative border-l border-slate-950 dark:border-slate-50"
            }
        },
        "item": {
            "content": {
                "root": {
                    "base": "mt-3 sm:pr-8"
                },
                "body": {
                    "base": "mb-4 text-base font-normal text-gray-500 dark:text-gray-400"
                },
                "time": {
                    "base": "mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
                },
                "title": {
                    "base": "text-lg font-semibold text-gray-900 dark:text-white"
                }
            },
            "point": {
                "horizontal": "flex items-center",
                "line": "hidden h-0.5 w-full bg-gray-200 dark:bg-gray-700 sm:flex",
                "marker": {
                    "base": {
                        "horizontal": "absolute -left-1.5 h-3 w-3 rounded-full border border-slate-950 dark:border-slate-50",
                        "vertical": "absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-slate-950 bg-slate-950 dark:border-slate-50 dark:bg-slate-50"
                    },
                    "icon": {
                        "base": "h-3 w-3 text-slate-50 dark:text-slate-950",
                        "wrapper": "absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-slate-950 dark:bg-slate-50"
                    }
                },
                "vertical": ""
            }
        }
    }
    // New useEffect for handling component activation based on conditions
    if (user.activeComponent !== 'SubComponentChatInterface') {
        return (null)
    }
    let chatThread = 'chat_threads' in course.courseLesson && typeof course.activeThread === 'number' ? course?.courseLesson?.chat_threads[course.activeThread] : null
    let chatItem = chatThread ? chatThread[0] : null
    let message: any = chatItem && 'message' in chatItem ? chatItem.message ?? null : null
    let parsed_message = myjsonrepair(message)
    let point = parsed_message ? JSON.parse(parsed_message) : undefined
    function getGradientColor(value: number) {
        // Ensure the value is within the range 0 to 100
        value = Math.max(0, Math.min(100, value));

        // Calculate the red and green values
        const red = Math.round(255 * (100 - value) / 100);
        const green = Math.round(255 * value / 100);

        // Convert the red and green values to hex strings
        const redHex = red.toString(16).padStart(2, '0');
        const greenHex = green.toString(16).padStart(2, '0');

        // Combine the red and green components, blue is 00 (no blue)
        const color = `#${redHex}${greenHex}00`;
        return color;
    }
    return (
        course.loading.courseGetLesson || course.loading.courseGetLessonIntroduction ? (
            <div className='m-12'>
                <TwLoading className='w-6 h-6' />
            </div>
        ) : (
            'chat_threads' in course.courseLesson && course.courseLesson.chat_threads ? (
                <div id='chatWindow' className='mt-8'>
                    <div className='flex flex-row w-11/12 m-auto justify-center'>
                        <span className="justify-center text-center font-semibold text-lg hover:cursor-pointer" onClick={() => setOpenIntroductionModal(true)}>
                            <FontAwesomeIcon icon={faGripLinesVertical} className='text-slate-950 dark:text-slate-50 mr-1' />
                            <>
                                {'point_titles' in course.courseLesson && typeof course.activeThread === 'number' ? course.courseLesson.point_titles[course.activeThread] : 0}
                            </>
                            <FontAwesomeIcon icon={faGripLinesVertical} className='text-slate-950 dark:text-slate-50 ml-1' />
                        </span>
                    </div>
                    <div className='m-auto w-[95%] !mb-60'>
                        <Timeline theme={FlowBiteTimeLineTheme} className='w-fit m-auto mt-8 border p-1 rounded-xl'>
                            {'point_titles' in course.courseLesson ? (
                                course.courseLesson?.point_titles?.map((point_title: string, idx: number) => (
                                    <Timeline.Item key={`point_timeline_item_${idx}`}>
                                        <Timeline.Point className='bg-slate-50' icon={course.activeThread === idx ? (HiArrowNarrowRight) : (undefined)} />
                                        <Timeline.Content>
                                            <Timeline.Title>
                                                <div className='flex flex-row justify-between'>
                                                    <div
                                                        key={`point_link_${idx}`}
                                                        className='cursor-pointer hover:text-slate-400 dark:text-gray-300 dark:hover:text-slate-50 mr-2'
                                                        onClick={() => {
                                                            dispatch(setActiveThread(Number(idx)));
                                                            setVideosModal(true);
                                                            dispatch(setActiveThread(Number(idx)));
                                                            dispatch(resetVideosList())
                                                            if (
                                                                'point_videos' in course.courseLesson &&
                                                                typeof course.activeThread === 'number' &&
                                                                course.courseLesson.point_videos &&
                                                                course.courseLesson.point_videos[Number(idx)] !== undefined) {
                                                                dispatch(courseGetYouTubeSearch({ query: `${course.courseLesson.point_videos[Number(idx)]}` }));
                                                            }
                                                        }
                                                        }
                                                    >
                                                        {idx + 1} {point_title}
                                                    </div>
                                                </div>
                                            </Timeline.Title>
                                            <Timeline.Body onClick={() => { dispatch(setActiveThread(Number(idx))) }} className=''>
                                            </Timeline.Body>
                                        </Timeline.Content>
                                    </Timeline.Item>
                                )
                                )) : (null)}
                        </Timeline>
                    </div>














                    <div className='flex flex-row w-full m-auto justify-center m-2 mt-4'>
                        <span className="justify-center text-center font-semibold text-lg">
                            {course.loading.generalTutorGetAssessment || course.loading.courseGetAssessment ? (
                                <TwLoading className="w-4 h-4 ml-2" />
                            ) : null}
                        </span>
                    </div>

                    <div className='flex flex-row w-10/12 m-auto justify-center mt-12 border-t pt-4 hidden'>
                        <TwButton
                            className='w-48 mt-2'
                            onClick={() => {
                                setRevisionModal(true);
                                setRevisionResolution('lesson')
                                if ('hid' in course.courseLesson && user.accessToken) {
                                    dispatch(courseGetRevisionAssessment(
                                        {
                                            lesson_hid: course.courseLesson.hid,
                                            revision_resolution: 'lesson',
                                            accessToken: user?.accessToken,
                                        }
                                    ))
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faGripLinesVertical} className='text-fuchsia-400	 mr-1' />
                            Revise lesson
                            <FontAwesomeIcon icon={faRotateRight} className='text-fuchsia-400 ml-1' />
                        </TwButton>
                        <TwButton
                            className='w-48 mt-2'
                            onClick={() => {
                                setRevisionModal(true);
                                setRevisionResolution('chapter')
                                if ('hid' in course.courseLesson && user.accessToken) {
                                    dispatch(courseGetRevisionAssessment(
                                        {
                                            lesson_hid: course.courseLesson.hid,
                                            revision_resolution: 'chapter',
                                            accessToken: user?.accessToken,
                                        }
                                    ))
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faGripLinesVertical} className='text-purple-400 mr-1' />
                            Revise chapter
                            <FontAwesomeIcon icon={faDumbbell} className='text-purple-400	ml-1' />
                        </TwButton>
                    </div>
                    <div className='flex flex-row w-10/12 m-auto justify-center mt-2 mb-48 hidden'>
                        <TwButton
                            className='w-56 mt-2'
                            onClick={() => {
                                setRevisionModal(true);
                                setRevisionResolution('course')
                                if ('hid' in course.courseLesson && user.accessToken) {
                                    dispatch(courseGetRevisionAssessment(
                                        {
                                            lesson_hid: course.courseLesson.hid,
                                            revision_resolution: 'course',
                                            accessToken: user?.accessToken,
                                        }
                                    ))
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faGripLinesVertical} className='text-yellow-300  mr-1' />
                            Revise course
                            <FontAwesomeIcon icon={faGraduationCap} className='text-yellow-300 ml-1' />
                        </TwButton>
                    </div>










                    <Modal show={openIntroductionModal} onClose={() => setOpenIntroductionModal(false)}>
                        <Modal.Header>Lesson Introduction</Modal.Header>
                        <Modal.Body>
                            {'courseLessonIntroduction' in course &&
                                course.courseLessonIntroduction
                                ? (
                                    <div className='flex flex-col items-start h-full mb-4'>
                                        <div className="w-full">
                                            <div className="flex flex-col ml-6 mr-5 md:ml-12 md:mr-10">
                                                <div className="flex items-center h-full m-auto w-full justify-center">
                                                    <div className='text-base max-w-prose prose prose-slate prose-sm dark:prose-invert w-full'>
                                                        <h3 className="text-2xl font-medium capitalize">
                                                            {course.courseLessonIntroduction['topic_name']}
                                                        </h3>

                                                        <Markdown remarkPlugins={[remarkMath, remarkGfm]} rehypePlugins={[rehypeKatex]}>{String(course.courseLessonIntroduction['introduction'])}</Markdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (null)}
                        </Modal.Body>
                        <Modal.Footer>
                            <TwButton
                                className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                                onClick={() => setOpenIntroductionModal(false)}>Close</TwButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={videosModal} onClose={() => setVideosModal(false)}
                        theme={
                            {
                                root: {
                                    sizes: { 'full': 'min-w-[100dvw] !p-2' },
                                },
                            }
                        }
                        position={'center'}
                        size={'full'}
                    >
                        <Modal.Header>
                            <div className='m-auto text-2xl'>
                                {'point_titles' in course.courseLesson && typeof course.activeThread === 'number' ? course.courseLesson.point_titles[course.activeThread] : 0}
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            {course.loading.courseGetYouTubeSearch ? (
                                <TwLoading />
                            ) : (
                                <div>
                                    {point !== undefined && point ? (
                                        <>
                                            {point['bar_separated_youtube_search_queries'] !== null && point['bar_separated_youtube_search_queries'] !== undefined ? (
                                                point['bar_separated_youtube_search_queries'].includes('|') ? (
                                                    point['bar_separated_youtube_search_queries'].split('|').map((query: string, index: number) => (
                                                        <TwButton key={index} onClick={() => { dispatch(courseGetYouTubeSearch({ query: `what is ${query}` })) }} className='mt-2 mb-4 ml-2 hidden'>
                                                            {query}
                                                        </TwButton>
                                                    ))
                                                ) : (null)
                                            ) : (null)}
                                        </>
                                    ) : (null)}
                                    {'point_videos' in course.courseLesson && typeof course.activeThread === 'number' && course.activeThread in Object.keys(course.courseLesson.point_videos) ? (
                                        course.courseLesson.point_videos[course.activeThread].split('|').map((query: string, index: number) => (
                                            <TwButton key={index} onClick={() => {
                                                if (user.accessToken) {
                                                    dispatch(courseGetYouTubeSearch({
                                                        query: `what is ${query}`,
                                                    }));
                                                }
                                            }
                                            } className='mt-2 mb-4 ml-2 hidden'>
                                                {query}
                                            </TwButton>
                                        ))
                                    ) : (null)}
                                    {course.videosList.hasOwnProperty('items') ? (
                                        <div className="flex flex-wrap justify-center pt-16 overflow-scroll">
                                            {'items' in course.videosList ? (
                                                course.videosList.items.map((
                                                    video: {
                                                        etag: string,
                                                        id: {
                                                            kind: string,
                                                            videoId: string
                                                        }
                                                        kind: string
                                                    },
                                                    index: number
                                                ) => (
                                                    <Card
                                                        key={`videosList-${index}`}
                                                        className={`
                                                    flex flex-row items-center rounded-lg 
                                                    transform transition-transform duration-250

                                                    dark:bg-slate-700
                                                    dark:hover:bg-slate-500

                                                    bg-slate-100
                                                    hover:bg-slate-200
                                                    p-[5px]
                                                    cursor-pointer
                                                    min-w-80
                                                    max-w-80
                                                    overflow-scroll
                                                    min-h-60
                                                    max-h-60
                                                    m-[5px]
                                                    `}
                                                        theme={
                                                            {
                                                                "root": {
                                                                    "children": "flex h-full flex-col justify-center gap-1 p-[2px]",
                                                                    "horizontal": {
                                                                        "off": "flex-col",
                                                                        "on": "flex-col md:max-w-xl md:flex-row"
                                                                    },
                                                                    "href": "hover:bg-gray-100 dark:hover:bg-gray-700"
                                                                },
                                                                "img": {
                                                                    "base": "",
                                                                    "horizontal": {
                                                                        "off": "rounded-t-lg",
                                                                        "on": "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    >
                                                        <iframe
                                                            className="w-full h-full border-xl"
                                                            src={`https://www.youtube.com/embed/${video.id.videoId}`}
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen
                                                            title={`Embedded youtube ${index}`}
                                                        />
                                                    </Card>
                                                ))
                                            ) : (null)
                                            }
                                        </div>
                                    ) : (null)}
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='flex flex-col w-auto m-auto'>
                                <div className='m-auto'>
                                    <TwButton
                                        className='w-28 mt-2'
                                        onClick={() => {
                                            setChatModal(true);
                                        }}
                                    >
                                        Lesson
                                    </TwButton>
                                </div>
                                <SubComponentAssessmentInterface>
                                </SubComponentAssessmentInterface>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={chatModal}
                        theme={
                            {
                                root: {
                                    sizes: { 'full': 'min-w-[100dvw] !p-2' },
                                },
                            }
                        }
                        position={'center'}
                        size={'full'}
                        onClose={() => setChatModal(false)}
                    >
                        <Modal.Header>
                            Chat
                        </Modal.Header>
                        <Modal.Body className='p-2 md:p-4'>
                            <div className='w-full'>
                                {course.courseLesson.chat_threads.map((item, threadIndex: number) => (
                                    <TwChatThread
                                        key={`chatthread_${threadIndex}`}
                                        threadIndex={threadIndex}
                                        newThread={Object.keys(item[0]).length === 0}
                                    >
                                    </TwChatThread>
                                ))}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {!user.hid || user.activeComponent !== 'SubComponentChatInterface' || !course.courseId || !course.lessonNumber ? (null) : (
                                <div className='flex flex-col w-[80%] m-auto justify-center'>
                                    <TwChatInput />
                                </div>
                            )}
                        </Modal.Footer>
                    </Modal >
                    <Modal
                        show={revisionModal}
                        theme={
                            {
                                root: {
                                    sizes: { 'full': 'min-w-[100dvw] !p-2' },
                                },
                            }
                        }
                        position={'center'}
                        size={'full'}
                        onClose={() => setRevisionModal(false)}
                    >
                        <Modal.Header>
                            Revision
                        </Modal.Header>
                        <Modal.Body className='p-2 md:p-4'>
                            <div className='w-full'>
                                {course.loading.courseGetRevisionAssessment ? (
                                    <TwLoading />
                                ) : (
                                    <TwAssessmentDetail is_revision={true} />
                                )}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {!course.loading.courseGetRevisionAssessment && course.courseAssessment && typeof course.revisionEloScore == 'number' ? (
                                <div className='flex flex-col w-full'>
                                    <TwProgressBar bgcolor={getGradientColor(course.revisionEloScore * 10)} progress={course.revisionEloScore * 10} />
                                    <div className='flex w-full justify-center mt-2'> Remaining in this level: {course.revisionEloNAssessments} </div>
                                    <div className='flex w-full justify-center mt-2'> Total Available: {course.revisionTotalNAssessment} </div>
                                    <div className='flex w-full justify-center'>
                                        <TwButton
                                            className="flex w-48 p-4 font-bold items-center h-full p-2 z-40 mt-4"
                                            onClick={() => {
                                                if (course.courseAssessment && 'thread_index' in course.courseAssessment) {
                                                    setRevisionModal(false);
                                                    dispatch(setActiveThread(course.courseAssessment.thread_index))
                                                    navigate(`/course/${course.courseId}/${course.courseAssessment.lnum}`)
                                                }
                                            }}
                                        >
                                            Go To Source
                                        </TwButton>
                                        <TwButton
                                            className="flex w-48 p-4 font-bold items-center h-full p-2 z-40 mt-4"
                                            onClick={() => {
                                                if ('hid' in course.courseLesson && user.accessToken) {
                                                    dispatch(courseGetRevisionAssessment(
                                                        {
                                                            lesson_hid: course.courseLesson.hid,
                                                            revision_resolution: revisionResolution,
                                                            accessToken: user?.accessToken,
                                                        }
                                                    ))
                                                }
                                            }}
                                        >
                                            Next Assessment
                                        </TwButton>
                                    </div>
                                </div>
                            ) : (
                                !course.loading.courseGetRevisionAssessment && !course.courseAssessment ? (
                                    <div className='flex w-full justify-center text-center'>
                                        Please make sure you have ALREADY completed some assessments for this lesson/chapter/course.
                                    </div>
                                ) : (null)
                            )}
                        </Modal.Footer>
                    </Modal >
                </div >
            ) : (
                'courseLessonIntroduction' in course &&
                    course.courseLessonIntroduction &&
                    !user.hid ? (
                    <>
                        <div className='flex flex-col items-start h-full mb-4'>
                            <div className="w-full">
                                <div className="flex flex-col ml-6 mr-5 md:ml-12 md:mr-10">
                                    <div className="flex items-center h-full m-auto w-full justify-center">
                                        <div className='text-base max-w-prose prose prose-slate prose-sm dark:prose-invert w-full'>
                                            <div className="text-4xl font-bold mb-4 capitalize text-center m-auto">
                                                {course.courseLessonIntroduction['course_name']} <br />
                                            </div>
                                            <div className=''>
                                                <button
                                                    type="submit"
                                                    className="border border-cyan-500 rounded-full w-3/4 text-center py-3 bg-gradient-to-r from-gray-50 to-white focus:outline-none my-1 flex items-center justify-center shadow-lg transform transition-transform duration-200 ease-in-out hover:scale-105 m-auto text-black hover:shadow-cyan-500/50"
                                                    onClick={() => {
                                                        dispatch(userCreateGuest())
                                                    }}
                                                >
                                                    <TwSprkles
                                                        className='hover:cursor-pointer fill-cyan-500 !inline-block align-middle !w-6 !h-6 mr-2'
                                                    />
                                                    <span className="font-bold text-baseline">
                                                        {user.loading.userCreateGuest ? (
                                                            <TwLoading />
                                                        ) : (
                                                            '  Continue as Guest  '
                                                        )}
                                                    </span>
                                                    <TwSprkles
                                                        className='hover:cursor-pointer fill-cyan-500 !inline-block align-middle !w-6 !h-6 ml-2'
                                                    />
                                                </button>
                                            </div>
                                            <h1 className="mt-16 text-2xl font-medium capitalize">
                                                {course.courseLessonIntroduction['chapter_name']} | {course.courseLessonIntroduction['topic_name']}
                                            </h1>

                                            <Markdown remarkPlugins={[remarkMath, remarkGfm]} rehypePlugins={[rehypeKatex]}>{course.courseLessonIntroduction['introduction']}</Markdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (null)
            )
        )
    )
}
export function SubComponentGeneralTutorChatInterface() {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    //
    const [openOutlineModal, setOpenOutlineModal] = useState(false)
    const [videosModal, setVideosModal] = useState(false)
    const [chatModal, setChatModal] = useState(false)
    const [revisionModal, setRevisionModal] = useState(false)
    const [revisionResolution, setRevisionResolution] = useState('')
    const [newLessonModal, setNewLessonModal] = useState(false)
    const [newLessonPrompt, setNewLessonPrompt] = useState('')
    const handleChange = (event: any) => {
        setNewLessonPrompt(event.target.value);
    };
    //
    useEffect(() => { // get the course lesson if it exists
        if (
            typeof course.generalTutorActiveLesson == 'string' &&
            user.accessToken
        ) {
            dispatch(generalTutorGetLesson({
                lesson_hid: course.generalTutorActiveLesson,
                accessToken: user.accessToken
            }))
        }
    }, [dispatch, user.accessToken, course.generalTutorActiveLesson])
    //
    useEffect(() => {
        if (
            'hid' in course.generalTutorLesson &&
            course.generalTutorLesson.hid &&
            course?.requestGeneralTutorLessonCreationResponse !== false &&
            typeof course?.requestGeneralTutorLessonCreationResponse !== 'boolean' &&
            course?.requestGeneralTutorLessonCreationResponse?.activation &&
            course.requestGeneralTutorLessonCreationResponse.lesson_hid === course.generalTutorLesson.hid &&
            user.accessToken
        ) {
            let request_body: CourseState['AIRequestBody'] = {
                lesson_hid: course.requestGeneralTutorLessonCreationResponse.lesson_hid,
                is_general_tutor: true,
            }
            dispatch(setRequestGeneralTutorLessonCreationResponse(false))
            dispatch(courseGetGeneralTutorLessonCreationRequestTicket(
                {
                    body_data: request_body,
                    accessToken: user.accessToken,
                }
            ))
        }
    }, [
        dispatch,
        course.requestAIResponse,
        course.generalTutorLesson,
        course.requestGeneralTutorLessonCreationResponse,
        user.accessToken
    ]
    )
    useEffect(() => {
        if (
            'hid' in course.generalTutorLesson &&
            course.generalTutorLesson.hid &&
            course.AIRequestTicket?.function_app_endpoint?.lesson_hid === course.generalTutorLesson.hid &&
            course.AIRequestTicket?.function_call?.name === 'create_lesson_outline'
        ) {
            dispatch(runGeneralTutorLessonCreationStream(
                {
                    requestTicket: course.AIRequestTicket,
                    request_body: course.AIRequestBody,
                }
            ))
        }
    }, [
        dispatch,
        course.AIRequestTicket,
        course.AIRequestBody,
        course.generalTutorLesson
    ]
    )
    if (
        user.activeComponent !== 'SubComponentGeneralTutorChatInterface'
    ) {
        return (null)
    }
    const FlowBiteTimeLineTheme = {
        "root": {
            "direction": {
                "horizontal": "items-base sm:flex",
                "vertical": "relative border-l border-slate-950 dark:border-slate-50"
            }
        },
        "item": {
            "content": {
                "root": {
                    "base": "mt-3 sm:pr-8"
                },
                "body": {
                    "base": "mb-4 text-base font-normal text-gray-500 dark:text-gray-400"
                },
                "time": {
                    "base": "mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
                },
                "title": {
                    "base": "text-lg font-semibold text-gray-900 dark:text-white"
                }
            },
            "point": {
                "horizontal": "flex items-center",
                "line": "hidden h-0.5 w-full bg-gray-200 dark:bg-gray-700 sm:flex",
                "marker": {
                    "base": {
                        "horizontal": "absolute -left-1.5 h-3 w-3 rounded-full border border-slate-950 dark:border-slate-50",
                        "vertical": "absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-slate-950 bg-slate-950 dark:border-slate-50 dark:bg-slate-50"
                    },
                    "icon": {
                        "base": "h-3 w-3 text-slate-50 dark:text-slate-950",
                        "wrapper": "absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-slate-950 dark:bg-slate-50"
                    }
                },
                "vertical": ""
            }
        }
    }
    let chatThread = 'chat_threads' in course.generalTutorLesson && typeof course.activeGeneralTutorThread === 'number' ? course?.generalTutorLesson?.chat_threads[course.activeGeneralTutorThread] : null
    let chatItem = chatThread ? chatThread[0] : null
    let message: any = chatItem && 'message' in chatItem ? chatItem.message ?? null : null
    let parsed_message = myjsonrepair(message)
    let point = parsed_message ? JSON.parse(parsed_message) : undefined
    function getGradientColor(value: number) {
        // Ensure the value is within the range 0 to 100
        value = Math.max(0, Math.min(100, value));

        // Calculate the red and green values
        const red = Math.round(255 * (100 - value) / 100);
        const green = Math.round(255 * value / 100);

        // Convert the red and green values to hex strings
        const redHex = red.toString(16).padStart(2, '0');
        const greenHex = green.toString(16).padStart(2, '0');

        // Combine the red and green components, blue is 00 (no blue)
        const color = `#${redHex}${greenHex}00`;
        return color;
    }
    return (
        course.loading.generalTutorGetLesson ? (
            <div className='m-12'>
                <TwLoading className='w-6 h-6' />
            </div>
        ) : (
            'chat_threads' in course.generalTutorLesson && course.generalTutorLesson.chat_threads ? (
                <div id='chatWindow' className='mt-8'>
                    {
                        course.generalTutorLesson.chat_threads.length > 0 ? (
                            <>
                                <div className='flex flex-row w-11/12 m-auto justify-center'>
                                    <span className="justify-center text-center font-semibold text-lg">
                                        <FontAwesomeIcon icon={faGripLinesVertical} className='text-slate-950 dark:text-slate-50 mr-1' />
                                        <>
                                            {
                                                'lessonpoints' in course.generalTutorLesson && course.generalTutorLesson.lessonpoints && typeof course.activeGeneralTutorThread === 'number' ?
                                                    course.generalTutorLesson.lessonpoints[course.activeGeneralTutorThread] : 0
                                            }
                                        </>
                                        <FontAwesomeIcon icon={faGripLinesVertical} className='text-slate-950 dark:text-slate-50 ml-1' />
                                    </span>
                                </div>
                                <div className='w-[95%] mb-48'>
                                    <Timeline theme={FlowBiteTimeLineTheme} className='w-[90%] m-auto mt-8 border p-1 rounded-xl'>
                                        {'lessonpoints' in course.generalTutorLesson ? (
                                            course.generalTutorLesson?.lessonpoints?.map((lessonpoint: string, idx: number) => (
                                                <Timeline.Item key={`point_timeline_item_${idx}`}>
                                                    <Timeline.Point className='bg-slate-50' icon={course.activeGeneralTutorThread === idx ? (HiArrowNarrowRight) : (undefined)} />
                                                    <Timeline.Content>
                                                        <Timeline.Title>
                                                            <div className='flex flex-row justify-between'>
                                                                <div
                                                                    key={`point_link_${idx}`}
                                                                    className='cursor-pointer hover:text-slate-400 dark:text-gray-300 dark:hover:text-slate-50 mr-2'
                                                                    onClick={() => {
                                                                        dispatch(setActiveGeneralTutorThread(Number(idx))); setOpenOutlineModal(false);
                                                                        setVideosModal(true);
                                                                        dispatch(setActiveGeneralTutorThread(Number(idx)));
                                                                        dispatch(resetVideosList())
                                                                        if (
                                                                            'lessonvideos' in course.generalTutorLesson &&
                                                                            typeof course.activeGeneralTutorThread === 'number' &&
                                                                            course.generalTutorLesson.lessonvideos &&
                                                                            course.generalTutorLesson.lessonvideos[Number(idx)] !== undefined) {
                                                                            dispatch(courseGetYouTubeSearch({ query: `${course.generalTutorLesson.lessonvideos[Number(idx)]}` }));
                                                                        }
                                                                    }}
                                                                >{idx + 1} {lessonpoint}
                                                                </div>
                                                            </div>
                                                        </Timeline.Title>
                                                        <Timeline.Body onClick={() => { dispatch(setActiveGeneralTutorThread(Number(idx))) }} className=''>
                                                        </Timeline.Body>
                                                    </Timeline.Content>
                                                </Timeline.Item>
                                            )
                                            )) : (null)}
                                    </Timeline>
                                </div>
                            </>
                        ) : (null)}

                    {course.generalTutorLesson.chat_threads.length < 1 ? (
                        course.loading.courseGetGeneralTutorLessonCreationRequestTicket || course.loading.lessonStreaming ? (
                            <TwLoading />
                        ) : (
                            <div className="flex flex-row mt-10 justify-center">
                                <TwButton
                                    onClick={() => {
                                        dispatch(setRequestGeneralTutorLessonCreationResponse({
                                            activation: true,
                                            lesson_hid: 'hid' in course.generalTutorLesson ? course.generalTutorLesson.hid : '',
                                        }))
                                    }}
                                    className="flex p-4 font-bold mr-2 justify-center items-center h-full p-2 z-40">
                                    Start Lesson
                                </TwButton>
                            </div>
                        )) : (null)}

                    <div className='w-full mb-48 hidden'>
                        {course.generalTutorLesson.chat_threads.length > 0 ? (
                            <>


                                <div className='flex flex-row w-full m-auto justify-center m-2 mt-4'>
                                    <span className="justify-center text-center font-semibold text-lg">
                                        {course.loading.generalTutorGetAssessment || course.loading.courseGetAssessment ? (
                                            <TwLoading className="w-4 h-4 ml-2" />
                                        ) : null}
                                    </span>
                                </div>

                                <div className='flex flex-row w-10/12 m-auto justify-center mt-2 border-t pt-4'>
                                    <TwButton
                                        className='w-48 mt-2'
                                        onClick={() => {
                                            setRevisionModal(true);
                                            setRevisionResolution('lesson')
                                            if ('hid' in course.generalTutorLesson && user.accessToken) {
                                                dispatch(courseGetRevisionAssessment(
                                                    {
                                                        lesson_hid: course.generalTutorLesson.hid,
                                                        is_general_tutor: true,
                                                        revision_resolution: 'lesson',
                                                        accessToken: user?.accessToken,
                                                    }
                                                ))
                                            }
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faGripLinesVertical} className='text-fuchsia-400	 mr-1' />
                                        Revise lesson
                                        <FontAwesomeIcon icon={faRotateRight} className='text-fuchsia-400 ml-1' />
                                    </TwButton>
                                </div>
                            </>
                        ) : (null)}

                    </div>






                    <Modal show={openOutlineModal} onClose={() => setOpenOutlineModal(false)}>
                        <Modal.Header>Lesson Outline</Modal.Header>
                        <Modal.Body>
                            <Timeline theme={FlowBiteTimeLineTheme}>
                                {'lessonpoints' in course.generalTutorLesson ? (
                                    course.generalTutorLesson?.lessonpoints?.map((lessonpoint: string, idx: number) => (
                                        <Timeline.Item key={`point_timeline_item_${idx}`}>
                                            <Timeline.Point className='bg-slate-50' icon={course.activeGeneralTutorThread === idx ? (HiArrowNarrowRight) : (undefined)} />
                                            <Timeline.Content>
                                                <Timeline.Title>
                                                    <div
                                                        key={`point_link_${idx}`}
                                                        className='cursor-pointer hover:text-slate-400 dark:hover:text-slate-950'
                                                        onClick={() => { dispatch(setActiveGeneralTutorThread(Number(idx))); setOpenOutlineModal(false); }}>{idx + 1} {lessonpoint}
                                                    </div>
                                                </Timeline.Title>
                                                <Timeline.Body>
                                                </Timeline.Body>
                                            </Timeline.Content>
                                        </Timeline.Item>
                                    )
                                    )) : (null)}
                            </Timeline>
                        </Modal.Body>
                        <Modal.Footer>
                            <TwButton
                                className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                                onClick={() => setOpenOutlineModal(false)}>Close</TwButton>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={videosModal} onClose={() => setVideosModal(false)}
                        theme={
                            {
                                root: {
                                    sizes: { 'full': 'min-w-[100dvw] !p-2' },
                                },
                            }
                        }
                        position={'center'}
                        size={'full'}
                    >
                        <Modal.Header>
                            <div className='m-auto text-2xl'>
                                {
                                    'lessonpoints' in course.generalTutorLesson && course.generalTutorLesson.lessonpoints && typeof course.activeGeneralTutorThread === 'number' ?
                                        course.generalTutorLesson.lessonpoints[course.activeGeneralTutorThread] : 0
                                }
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            {course.loading.courseGetYouTubeSearch ? (
                                <TwLoading />
                            ) : (
                                <div>
                                    {point !== undefined || point ? (
                                        <>
                                            {point['bar_separated_youtube_search_queries'] !== null && point['bar_separated_youtube_search_queries'] !== undefined ? (
                                                point['bar_separated_youtube_search_queries'].includes('|') ? (
                                                    point['bar_separated_youtube_search_queries'].split('|').map((query: string, index: number) => (
                                                        <TwButton
                                                            key={index}
                                                            onClick={() => { dispatch(courseGetYouTubeSearch({ query: `what is ${query}` })) }}
                                                            className='mt-0 mb-0 ml-2 hidden'
                                                        >
                                                            {query}
                                                        </TwButton>
                                                    ))
                                                ) : (null)
                                            ) : (null)}
                                        </>
                                    ) : (null)}
                                    {
                                        'lessonvideos' in course.generalTutorLesson &&
                                            typeof course.activeGeneralTutorThread === 'number' &&
                                            course.generalTutorLesson.lessonvideos &&
                                            course.generalTutorLesson.lessonvideos[course.activeGeneralTutorThread] !== undefined
                                            ? (
                                                course.generalTutorLesson.lessonvideos[course.activeGeneralTutorThread].split('|').map((query: string, index: number) => (
                                                    <TwButton key={index} onClick={() => { dispatch(courseGetYouTubeSearch({ query: `what is ${query}` })) }} className='mt-0 mb-0 ml-2 hidden'>
                                                        {query}
                                                    </TwButton>
                                                ))
                                            ) : (null)}
                                    {course.videosList.hasOwnProperty('items') ? (
                                        <div className="flex flex-wrap justify-center pt-4 overflow-scroll">
                                            {'items' in course.videosList ? (
                                                course.videosList.items.map((
                                                    video: {
                                                        etag: string,
                                                        id: {
                                                            kind: string,
                                                            videoId: string
                                                        }
                                                        kind: string
                                                    },
                                                    index: number
                                                ) => (
                                                    <Card
                                                        key={`videosList-${index}`}
                                                        className={`
                                                    flex flex-row items-center rounded-lg 
                                                    transform transition-transform duration-250

                                                    dark:bg-slate-700
                                                    dark:hover:bg-slate-500

                                                    bg-slate-100
                                                    hover:bg-slate-200
                                                    p-[5px]
                                                    cursor-pointer
                                                    min-w-80
                                                    max-w-80
                                                    overflow-scroll
                                                    min-h-60
                                                    max-h-60
                                                    m-[5px]
                                                    `}
                                                        theme={
                                                            {
                                                                "root": {
                                                                    "children": "flex h-full flex-col justify-center gap-1 p-[2px]",
                                                                    "horizontal": {
                                                                        "off": "flex-col",
                                                                        "on": "flex-col md:max-w-xl md:flex-row"
                                                                    },
                                                                    "href": "hover:bg-gray-100 dark:hover:bg-gray-700"
                                                                },
                                                                "img": {
                                                                    "base": "",
                                                                    "horizontal": {
                                                                        "off": "rounded-t-lg",
                                                                        "on": "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    >
                                                        <iframe
                                                            className="w-full h-[300px]"
                                                            src={`https://www.youtube.com/embed/${video.id.videoId}`}
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen
                                                            title={`Embedded youtube ${index}`}
                                                        />
                                                    </Card>
                                                ))
                                            ) : (null)
                                            }
                                        </div>

                                    ) : (null)}
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='flex flex-col w-full m-auto'>
                                <div className='m-auto'>
                                    <TwButton
                                        className='w-28 mt-2'
                                        onClick={() => {
                                            setChatModal(true);
                                        }}
                                    >
                                        Lesson
                                    </TwButton>
                                </div>
                                <SubComponentGeneralTutorAssessmentInterface>
                                </SubComponentGeneralTutorAssessmentInterface>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={chatModal}
                        theme={
                            {
                                root: {
                                    sizes: { 'full': 'min-w-[100dvw] !p-2' },
                                },
                            }
                        }
                        position={'center'}
                        size={'full'}
                        onClose={() => setChatModal(false)}
                    >
                        <Modal.Header>
                            Chat
                        </Modal.Header>
                        <Modal.Body className='p-2 md:p-4'>
                            {course.generalTutorLesson.chat_threads.length > 0 ? (
                                course.generalTutorLesson.chat_threads.map((item, threadIndex: number) => (
                                    <TwGeneralTutorChatThread
                                        key={`chatthread_${threadIndex}`}
                                        threadIndex={threadIndex}
                                        newThread={Object.keys(item[0]).length === 0}
                                    >
                                    </TwGeneralTutorChatThread>
                                ))
                            ) : (null)}
                        </Modal.Body>
                        <Modal.Footer>
                            {!user.hid || user.activeComponent !== 'SubComponentGeneralTutorChatInterface' ? (null) : (
                                <div className='flex flex-col w-[80%] m-auto justify-center'>
                                    <TwGeneralTutorChatInput />
                                </div>
                            )}
                        </Modal.Footer>
                    </Modal >
                    <Modal
                        show={revisionModal}
                        theme={
                            {
                                root: {
                                    sizes: { 'full': 'min-w-[100dvw] !p-2' },
                                },
                            }
                        }
                        position={'center'}
                        size={'full'}
                        onClose={() => setRevisionModal(false)}
                    >
                        <Modal.Header>
                            Revision
                        </Modal.Header>
                        <Modal.Body className='p-2 md:p-4'>
                            <div className='w-full'>
                                {course.loading.courseGetRevisionAssessment ? (
                                    <TwLoading />
                                ) : (
                                    <TwGeneralTutorAssessmentDetail is_revision={true} />
                                )}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {!course.loading.courseGetRevisionAssessment && course.generalTutorAssessment && typeof course.revisionEloScore == 'number' ? (
                                <div className='flex flex-col w-full'>
                                    <TwProgressBar bgcolor={getGradientColor(course.revisionEloScore * 10)} progress={course.revisionEloScore * 10} />
                                    <div className='flex w-full justify-center mt-2'> Remaining in this level: {course.revisionEloNAssessments} </div>
                                    <div className='flex w-full justify-center mt-2'> Total Available: {course.revisionTotalNAssessment} </div>
                                    <div className='flex w-full justify-center'>
                                        <TwButton
                                            className="flex w-48 p-4 font-bold items-center h-full p-2 z-40 mt-4"
                                            onClick={() => {
                                                if (course.generalTutorAssessment && 'thread_index' in course.generalTutorAssessment) {
                                                    setRevisionModal(false);
                                                    dispatch(setActiveGeneralTutorThread(course.generalTutorAssessment.thread_index))
                                                }
                                            }}
                                        >
                                            Go To Source
                                        </TwButton>
                                        <TwButton
                                            className="flex w-48 p-4 font-bold items-center h-full p-2 z-40 mt-4"
                                            onClick={() => {
                                                if ('hid' in course.generalTutorLesson && user.accessToken) {
                                                    dispatch(courseGetRevisionAssessment(
                                                        {
                                                            lesson_hid: course.generalTutorLesson.hid,
                                                            is_general_tutor: true,
                                                            revision_resolution: revisionResolution,
                                                            accessToken: user?.accessToken,
                                                        }
                                                    ))
                                                }
                                            }}
                                        >
                                            Next Assessment
                                        </TwButton>
                                    </div>
                                </div>
                            ) : (
                                !course.loading.courseGetRevisionAssessment && !course.generalTutorAssessment ? (
                                    <div className='flex w-full justify-center text-center'>
                                        Please make sure you have ALREADY completed some assessments for this lesson/chapter/course.
                                    </div>
                                ) : (null)
                            )}
                        </Modal.Footer>
                    </Modal >
                </div>
            ) : (
                user.hid ? (
                    <>
                        <div
                            className={`
                                flex flex-row items-center rounded-lg 
                                transform transition-transform duration-250

                                p-1
                                cursor-pointer
                                w-[45%]
                                min-h-[50px]
                                mb-2
                                text-center
                                justify-center
                                rounded-lg
                                border
                                border-dashed

                                dark:border-slate-500
                                dark:hover:border-slate-300

                                border-gray-400
                                hover:border-gray-900
                                m-auto
                                mt-auto
                            `}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setNewLessonModal(true);
                            }}
                        >
                            <div>
                                New Mini Lesson
                            </div>
                        </div>
                        <Modal show={newLessonModal} onClose={() => setNewLessonModal(false)}>
                            <Modal.Header>Create any new lesson</Modal.Header>
                            <Modal.Body>
                                <div className={` flex flex-col text-slate-950`}>
                                    <div className={`
                            container mx-auto flex-1 flex flex-col items-center justify-center px-2
                        `}>
                                        <div className={`px-6 py-8 rounded shadow-md w-full
                                bg-slate-200 text-slate-950 dark:bg-slate-900 dark:text-slate-100
                            `}>
                                            <textarea id="message" rows={4}
                                                className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                                                placeholder="Write your lesson description here..." name='message'
                                                value={newLessonPrompt}
                                                onChange={handleChange}
                                            >
                                                {newLessonPrompt}
                                            </textarea>

                                            {user.loading.userContact ? (
                                                <button
                                                    className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                                                >
                                                    <TwLoading />
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                                                    onClick={() => {
                                                        if (user.hid) {
                                                            dispatch(generalTutorCreateLesson(
                                                                { user_prompt: newLessonPrompt, accessToken: 'accessToken' in user && user.accessToken ? user.accessToken : '' }
                                                            ))
                                                            setTimeout(() => {
                                                                setNewLessonModal(false);
                                                                setNewLessonPrompt('');
                                                            }, 300);
                                                            dispatch(setActiveComponent('SubComponentGeneralTutorChatInterface'))
                                                        }
                                                    }}
                                                >
                                                    {course.loading.generalTutorCreateLesson ? (
                                                        <TwLoading />
                                                    ) : (
                                                        'Create Lesson'
                                                    )}
                                                </button>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <TwButton onClick={() => setNewLessonModal(false)}>Close</TwButton>
                            </Modal.Footer>
                        </Modal >
                    </>
                ) : (null)
            ))
    )
}
export function SubComponentGeneralTutorAssessmentInterface() {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const [assessmentModal, setAssessmentModal] = useState(false);
    // New useEffect for handling component activation based on conditions
    if (user.activeComponent !== 'SubComponentGeneralTutorChatInterface') {
        return (null)
    }
    return (
        <>
            <TwGeneralTutorNewAssessmentUI setAssessmentModal={setAssessmentModal} />
            {!course.loading.generalTutorGetAssessment && course?.generalTutorAssessment ? (
                <Modal
                    show={assessmentModal}
                    theme={
                        {
                            root: {
                                sizes: { 'full': 'min-w-[100dvw] !p-2' },
                            },
                        }
                    }
                    position={'center'}
                    size={'full'}
                    onClose={() => setAssessmentModal(false)}
                >
                    <Modal.Header>
                        {
                            course.generalTutorAssessment ?
                                course.generalTutorAssessment.assessment_type : '-'
                        }
                    </Modal.Header>
                    <Modal.Body className='p-2 md:p-4'>
                        <div className="space-y-6 p-2 md:p-6">
                            <TwGeneralTutorAssessmentDetail />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <TwButton onClick={() => setAssessmentModal(false)}>
                            Close
                        </TwButton>
                    </Modal.Footer>
                </Modal >
            ) : (null)
            }
        </>
    )
}
export function SubComponentAssessmentInterface() {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const [assessmentModal, setAssessmentModal] = useState(false);
    // New useEffect for handling component activation based on conditions
    if (user.activeComponent !== 'SubComponentChatInterface') {
        return (null)
    }
    return (
        <>
            <TwNewAssessmentUI setAssessmentModal={setAssessmentModal} />
            {!course.loading.courseGetAssessment && course?.courseAssessment ? (
                <Modal
                    show={assessmentModal}
                    theme={
                        {
                            root: {
                                sizes: { 'full': 'min-w-[100dvw] !p-2' },
                            },
                        }
                    }
                    position={'center'}
                    size={'full'}
                    onClose={() => setAssessmentModal(false)}
                >
                    <Modal.Header>
                        {course.courseAssessment && 'assessment_type' in course.courseAssessment ? course.courseAssessment.assessment_type : '-'}
                    </Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6 p-1 md:p-6">
                            <TwAssessmentDetail />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <TwButton onClick={() => setAssessmentModal(false)}>
                            Close
                        </TwButton>
                    </Modal.Footer>
                </Modal >
            ) : (null)
            }
        </>
    )
}
export function SubComponentCourseControl() {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    //const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    let navigate = useNavigate();
    const [guestModal, setGuestModal] = useState(false)
    const [newCourseModal, setNewCourseModal] = useState(false)
    const [newCoursePrompt, setNewCoursePrompt] = useState('')
    const handleChange = (event: any) => {
        setNewCoursePrompt(event.target.value);
    };
    // 
    useEffect(() => {
        if (user.navTab === 'ByYou' && user.hid) {
            dispatch(generalTutorGetLessonList(
                { page: 1, accessToken: 'accessToken' in user && user.accessToken ? user.accessToken : '' }
            ))
        }
    }, [dispatch, user, user.navTab, user.accessToken])
    useEffect(() => {
        if (user.navTab === 'ByYou' && user.hid) {
            dispatch(generalTutorGetCourseList(
                { accessToken: 'accessToken' in user && user.accessToken ? user.accessToken : '' }
            ))
        }
    }, [dispatch, user, user.navTab, user.accessToken])
    return (
        <>
            {user.navTab === 'ByYou' ? (
                <>
                    <div className={`flex flex-wrap flex-row p-2 w-[95%] m-auto gap-1`}>
                        <div
                            className={`
                                flex flex-row items-center rounded-lg 
                                transform transition-transform duration-250

                                p-1
                                cursor-pointer
                                text-center
                                justify-center
                                rounded-lg
                                border
                                border-dashed

                                dark:border-slate-500
                                dark:hover:border-slate-300

                                border-gray-400
                                hover:border-gray-900
                                w-[100%]
                                md:min-w-60
                                md:max-w-60
                                md:overflow-scroll
                                min-h-32
                                max-h-32
                            `}
                            onClick={() => {
                                if (user.hid) {
                                    setNewCourseModal(true)
                                } else {
                                    setGuestModal(true)
                                }
                            }}
                        >
                            <div>
                                Make Your Own Course
                            </div>
                        </div>
                        <TwGeneralTutorCardBasic
                            className={'mt-2 mr-1 mb-1 p-1'}
                            onClick={() => {
                                if (user.hid) {
                                    dispatch(setActiveComponent('SubComponentGeneralTutorChatInterface'))
                                    navigate('/miniLessons')
                                } else {
                                    setGuestModal(true)
                                }
                            }}
                        >
                            MiniLessons
                        </TwGeneralTutorCardBasic>
                        {guestModal ? (
                            <TwGuestLoginModal guestModal={guestModal} setGuestModal={setGuestModal} />
                        ) : (null)}
                    </div>
                    <Modal show={newCourseModal} onClose={() => setNewCourseModal(false)}>
                        <Modal.Header>Create a New Course</Modal.Header>
                        <Modal.Body>
                            <div className={` flex flex-col text-slate-950`}>
                                <div className={`
                            container mx-auto flex-1 flex flex-col items-center justify-center px-2
                        `}>
                                    <div className={`px-6 py-8 rounded shadow-md w-full
                                bg-slate-200 text-slate-950 dark:bg-slate-900 dark:text-slate-100
                            `}>
                                        <textarea id="message" rows={4}
                                            className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                                            placeholder="Write your course description here..." name='message'
                                            value={newCoursePrompt}
                                            onChange={handleChange}
                                        >
                                            {newCoursePrompt}
                                        </textarea>

                                        {user.loading.userContact ? (
                                            <button
                                                className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                                            >
                                                <TwLoading />
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                                                onClick={() => {
                                                    if (user.hid) {
                                                        dispatch(generalTutorCreateCourse(
                                                            { user_prompt: newCoursePrompt, accessToken: 'accessToken' in user && user.accessToken ? user.accessToken : '' }
                                                        ))
                                                        setTimeout(() => {
                                                            setNewCourseModal(false);
                                                            setNewCoursePrompt('');
                                                        }, 300);
                                                    }
                                                }}
                                            >
                                                {course.loading.generalTutorCreateCourse ? (
                                                    <TwLoading />
                                                ) : (
                                                    'Create Course'
                                                )}
                                            </button>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <TwButton onClick={() => setNewCourseModal(false)}>Close</TwButton>
                        </Modal.Footer>
                    </Modal >
                </>
            ) : (null)}
            {user.navTab === 'Saved' ? (
                <SubComponentSubscriptionCourses />
            ) : (null)}
        </>
    )
}
export function SubComponentPerformanceInterface() {
    return (
        <PrivateComponentPerformanceStatistics />
    )
}
export function SubComponentStripeDashboard() {
    // global states and disptach
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch<AppDispatch>();
    const APIURL = process.env.REACT_APP_API_URL
    const [stripeConnectInstance, setStripeConnectInstance] = useState<any | null>(null);

    useEffect(() => {
        if (user.activeComponent === 'SubComponentStripeDashboard') {
            setStripeConnectInstance(() => {
                const fetchClientSecret = async () => {
                    const response = await fetch(
                        `${APIURL}/affiliate_embedded_components_link.json`,
                        {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + String(user.accessToken),
                            }
                        }
                    )
                    // Fetch the AccountSession client secret
                    if (!response.ok) {
                        // Handle errors on the client side here
                        console.error('An error occurred with loading client secret');
                        return undefined;
                    } else {
                        const { data } = await response.json();
                        const clientSecret = data['client_secret']
                        return clientSecret;
                    }
                }
                return loadConnectAndInitialize({
                    // This is your test publishable API key.
                    publishableKey: "pk_live_51HS6KGCUEyV7FMWeKoOd9IZ5Fd7Feiscm2pbt0ANrnJMv4HGKw5DODOHc8SD4Zb9fvDw0wIOjFQv1AeqXu4r1mxP00WkP5jhtI",
                    fetchClientSecret: fetchClientSecret,
                })
            }
            )
            if (user.accessToken) {
                dispatch(userGetAffiliateTally({ accessToken: user.accessToken }))
            }
        }
    }, [dispatch, user.accessToken, user.activeComponent, APIURL]); // Dependency array    //

    if (
        user.activeComponent !== 'SubComponentStripeDashboard' ||
        !user.accessToken ||
        !stripeConnectInstance
    ) {
        return (null)
    }
    return (
        <>
            <div className="w-[95%] m-auto mt-8">
                <div className='mb-2'>
                    <div className='flex md:flex-row flex-col border-b broder-black'>

                        <div className='flex flex-row md:!flex-col justify-between pr-4'>
                            <div className='md:mb-2 text-[#717171] text-[14px]'>
                                Affiliate Promotion Code
                            </div>
                            <div className='text-[18px] mb-1'>
                                {user.loading.userGetAffiliateTally ? (
                                    <TwLoading />
                                ) : (
                                    user.affiliate_promo_code ? user.affiliate_promo_code : 'None'
                                )}
                            </div>
                        </div>

                        <div className='flex flex-row md:!flex-col justify-between pr-4'>
                            <div className='md:mb-2 text-[#717171] text-[14px]'>
                                Total Conversions
                            </div>
                            <div className='text-[18px] mb-1'>
                                {user.loading.userGetAffiliateTally ? (
                                    <TwLoading />
                                ) : (
                                    user.affiliate_tally
                                )}
                            </div>
                        </div>

                        <div className='flex flex-row md:!flex-col justify-between pr-4'>
                            <div className='md:mb-2 text-[#717171] text-[14px]'>
                                Refresh
                            </div>
                            <div className='text-[18px]'>
                                <TwButton
                                    onClick={() => {
                                        if (user.accessToken && !user.loading.userGetAffiliateTally) {
                                            dispatch(userGetAffiliateTally({ accessToken: user.accessToken }))
                                        }
                                    }}
                                    className="flex p-2 m-2"
                                >
                                    <FontAwesomeIcon icon={faArrowsRotate} />
                                </TwButton>
                            </div>
                        </div>
                    </div>
                </div>
                <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                    <ConnectPayouts />
                    <div className='mt-8'>
                    </div>
                </ConnectComponentsProvider>
            </div>
        </>
    )
}
export function SubComponentUserRegistration() {
    // global states and disptach
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch<AppDispatch>();
    //
    useEffect(() => {
        if (
            user.activeComponent === 'SubComponentUserRegistration' &&
            !user.activeSubComponent
        ) {
            dispatch(setActiveSubComponent('PrivateComponentLogin'))
        }
    }, [
        dispatch,
        user.activeComponent,
        user.activeSubComponent
    ])

    if (
        user.activeComponent !== 'SubComponentUserRegistration' ||
        (user.hid && !user.is_guest)
    ) {
        return (null)
    }
    return (
        <>
            <PrivateComponentLogin>
            </PrivateComponentLogin>

            <PrivateComponentSignUp>
            </PrivateComponentSignUp>

            <PrivateComponentForgotPassword>
            </PrivateComponentForgotPassword>

            <PrivateComponentPasswordReset>
            </PrivateComponentPasswordReset>


            <PrivateComponentActivateAccount>
            </PrivateComponentActivateAccount>

        </>
    )
}
export function SubComponentExplore() {
    //
    const user = useSelector((state: RootState) => state.user)
    //
    if (user.activeComponent !== 'SubComponentExplore') {
        return (null)
    }
    return (
        <>
            <div className='basis-12/12 overflow-hidden'>
                <TwHome>
                </TwHome>

                <SubComponentTutorial>
                </SubComponentTutorial>


                <SubComponentCourseControl>
                </SubComponentCourseControl>


                {user.navTab === 'FindCourses' ? (
                    <SubComponentCatagoryCoureses>
                    </SubComponentCatagoryCoureses>
                ) : (null)}

                <SubComponentFooter />

            </div>
        </>
    )
}
export function SubComponentContactForm() {
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch<AppDispatch>();
    let handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        // Retrieve all form elements
        const messageSubject = form.elements.namedItem('subject') as HTMLInputElement;
        const message = form.elements.namedItem('message') as HTMLInputElement;
        if (user.hid && user.accessToken && messageSubject.value && message.value) {
            dispatch(userContact({
                messageSubject: messageSubject.value,
                message: message.value,
                accessToken: user.accessToken
            }))
        } else {
            // Handle the case where elements could not be found
            toast.error(`Missing information, please try again.`);
        }
    }
    return (
        <div className={` flex flex-col text-slate-950`}>
            <div className={`
		    container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2
		    `}>
                <div className={`px-6 py-8 rounded shadow-md w-full
			bg-slate-200 text-slate-950 dark:bg-slate-900 dark:text-slate-100
			`}>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="subject" className="block mb-2 text-sm font-medium">Subject: </label>

                        <input
                            type='text' name='subject' placeholder='Reason for contact...'
                            className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                        />

                        <label htmlFor="message" className="block mb-2 text-sm font-medium ">Message: </label>
                        <textarea id="message" rows={4}
                            className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                            placeholder="Write your message here..." name='message'></textarea>

                        {user.loading.userContact ? (
                            <button
                                className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                            >
                                <TwLoading />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                            >Send</button>
                        )}
                    </form>
                </div>

            </div>
        </div>
    )
}
export function SubComponentCourseOutline() {
    const course = useSelector((state: RootState) => state.course)
    //
    return (
        course.lessonNumber && course.courseId ? (
            <>
                <div className={`
                    flex flex-col w-12/12 m-0 text-[9px] mb-12
                `}>
                    <div className=''>
                        <TwAccordion>
                        </TwAccordion>
                    </div>
                </div>
            </>
        ) : (null)
    )
}
export function SubComponentGeneralTutorOutline() {
    const user = useSelector((state: RootState) => state.user)
    const course = useSelector((state: RootState) => state.course)
    const dispatch = useDispatch<AppDispatch>();
    const [deleteLessonModal, setDeleteLessonModal] = useState(false)
    const [targetGeneralTutorLessonHid, setTargetGeneralTutorLessonHid] = useState('')
    const [newLessonModal, setNewLessonModal] = useState(false)
    const [newLessonPrompt, setNewLessonPrompt] = useState('')
    useEffect(() => {
        if (!('hid' in course.generalTutorLesson) && course.generalTutorLessonList.length > 0) {
            dispatch(setGeneralTutorActiveLesson(course.generalTutorLessonList[0].hid))
            dispatch(setActiveGeneralTutorThread(0))
        } else if (course.generalTutorLessonList.length === 0) {
            setNewLessonModal(true)
        }
    }, [dispatch, course.generalTutorLessonList, user, user.activeComponent, course.generalTutorLesson])

    const handleChange = (event: any) => {
        setNewLessonPrompt(event.target.value);
    };
    //
    return (
        <>
            {course.loading.generalTutorGetLessonList ? (<TwLoading />) : (
                <>


                    {user.hid ? (
                        <>
                            <div
                                className={`
                                flex flex-row items-center w-full rounded-lg 
                                transform transition-transform duration-250

                                p-1
                                cursor-pointer
                                max-w-[95%]
                                min-h-[50px]
                                mb-2
                                text-center
                                justify-center
                                rounded-lg
                                border
                                border-dashed

                                dark:border-slate-500
                                dark:hover:border-slate-300

                                border-gray-400
                                hover:border-gray-900
                                m-auto
                            `}
                                onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    setNewLessonModal(true);
                                }}
                            >
                                <div>
                                    New Mini Lesson
                                </div>
                            </div>
                            <Modal show={newLessonModal} onClose={() => setNewLessonModal(false)}>
                                <Modal.Header>Create any new lesson</Modal.Header>
                                <Modal.Body>
                                    <div className={` flex flex-col text-slate-950`}>
                                        <div className={`
                            container mx-auto flex-1 flex flex-col items-center justify-center px-2
                        `}>
                                            <div className={`px-6 py-8 rounded shadow-md w-full
                                bg-slate-200 text-slate-950 dark:bg-slate-900 dark:text-slate-100
                            `}>
                                                <textarea id="message" rows={4}
                                                    className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                                                    placeholder="Write your lesson description here..." name='message'
                                                    value={newLessonPrompt}
                                                    onChange={handleChange}
                                                >
                                                    {newLessonPrompt}
                                                </textarea>

                                                {user.loading.userContact ? (
                                                    <button
                                                        className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                                                    >
                                                        <TwLoading />
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="submit"
                                                        className="block w-full p-3 rounded mb-4 border dark:border-gray-600 dark:bg-gray-800 dark:text-white border-gray-300 bg-white text-gray-900"
                                                        onClick={() => {
                                                            if (user.hid) {
                                                                dispatch(generalTutorCreateLesson(
                                                                    { user_prompt: newLessonPrompt, accessToken: 'accessToken' in user && user.accessToken ? user.accessToken : '' }
                                                                ))
                                                                setTimeout(() => {
                                                                    setNewLessonModal(false);
                                                                    setNewLessonPrompt('');
                                                                }, 300);
                                                                dispatch(setActiveComponent('SubComponentGeneralTutorChatInterface'))
                                                            }
                                                        }}
                                                    >
                                                        {course.loading.generalTutorCreateLesson ? (
                                                            <TwLoading />
                                                        ) : (
                                                            'Create Lesson'
                                                        )}
                                                    </button>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <TwButton onClick={() => setNewLessonModal(false)}>Close</TwButton>
                                </Modal.Footer>
                            </Modal >
                        </>
                    ) : (null)}
                    <div className={`
                    flex flex-col w-12/12 m-0 text-[9px] mb-12
                `}>
                        <div>
                            <InfiniteScroll
                                dataLength={course.generalTutorLessonList.length}
                                next={() => {
                                    dispatch(generalTutorGetLessonPage(
                                        { page: course.generalTutorInfinteScroll.nextPage, accessToken: 'accessToken' in user && user.accessToken ? user.accessToken : '' }
                                    ))
                                }}
                                hasMore={course.generalTutorInfinteScroll.hasMore}
                                loader={<div className='flex justify-center p-4'><TwLoading /></div>}
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>You have seen it all</b>
                                    </p>
                                }
                                scrollableTarget="scrollableDiv"
                            >
                                {course.generalTutorLessonList.map((general_tutor_lesson, index) => (
                                    <div className='py-1 text-sm' key={`GeneralTutorLesson_item_${index}`}>
                                        <div>
                                            <div
                                                onClick={() => {
                                                    dispatch(setGeneralTutorActiveLesson(general_tutor_lesson.hid))
                                                    dispatch(setActiveGeneralTutorThread(0))
                                                    dispatch(setActiveComponent('SubComponentGeneralTutorChatInterface'))
                                                    if (user.isMdScreen) {
                                                        dispatch(setShowSideBar(false))
                                                    }
                                                }
                                                }
                                                className={` 
                                                        ml-1
                                                        font-medium
                                                        border-l
                                                ${course.generalTutorActiveLesson === general_tutor_lesson.hid
                                                        ? `!bg-gray-600 text-slate-50`
                                                        : `hover:!text-slate-400`
                                                    }
                                                        overflow-scroll flex flex-row items-center p-1
                                                        cursor-pointer
                                                        rounded-r-md
                                                    `}
                                            >
                                                <div className='flex flex-row basis-10/12'>
                                                    {'completed' in general_tutor_lesson && general_tutor_lesson.completed ? (
                                                        <FontAwesomeIcon icon={faCrown} className='mr-[5px] text-yellow-300' />
                                                    ) : (
                                                        <FontAwesomeIcon icon={faSpinner} className='mr-[5px]' />
                                                    )}
                                                    {'lessontitle' in general_tutor_lesson && general_tutor_lesson.lessontitle ?
                                                        general_tutor_lesson.lessontitle :
                                                        general_tutor_lesson.query

                                                    }
                                                </div>
                                                {course.generalTutorActiveLesson === general_tutor_lesson.hid ? (
                                                    < div className='basis-2/12 flex justify-end align-right mr-2'>
                                                        {'completed' in general_tutor_lesson ? (
                                                            !general_tutor_lesson.completed ? (
                                                                <FontAwesomeIcon
                                                                    icon={faSquareCheck}
                                                                    size="lg"
                                                                    className='hover:cursor-pointer text-green-600 hover:text-green-200'
                                                                    onClick={(event) => {
                                                                        event.preventDefault();
                                                                        event.stopPropagation();
                                                                        dispatch(generalTutorUpdateLesson({
                                                                            hid: 'hid' in general_tutor_lesson ? general_tutor_lesson.hid : '',
                                                                            accessToken: 'accessToken' in user && user.accessToken ? user.accessToken : '',
                                                                            lesson: { ...general_tutor_lesson, completed: true }
                                                                        }))
                                                                    }}
                                                                />
                                                            ) : (
                                                                <FontAwesomeIcon
                                                                    icon={faSquareXmark}
                                                                    size="lg"
                                                                    className='hover:cursor-pointer text-gray-400 hover:text-gray-200'
                                                                    onClick={(event) => {
                                                                        event.preventDefault();
                                                                        event.stopPropagation();
                                                                        dispatch(generalTutorUpdateLesson({
                                                                            hid: 'hid' in general_tutor_lesson ? general_tutor_lesson.hid : '',
                                                                            accessToken: 'accessToken' in user && user.accessToken ? user.accessToken : '',
                                                                            lesson: { ...general_tutor_lesson, completed: false }
                                                                        }))
                                                                    }}
                                                                />
                                                            )
                                                        ) : (null)
                                                        }
                                                        <FontAwesomeIcon
                                                            icon={faSquareXmark}
                                                            size="lg"
                                                            className='hover:cursor-pointer text-red-400 hover:text-red-200 ml-1'
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                setDeleteLessonModal(true);
                                                                setTargetGeneralTutorLessonHid('hid' in general_tutor_lesson ? general_tutor_lesson.hid : '')
                                                            }}
                                                        />
                                                    </div>
                                                ) : (null)}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </InfiniteScroll>
                        </div >
                    </div >
                    <Modal show={deleteLessonModal} size="md" onClose={() => setDeleteLessonModal(false)} popup>
                        <Modal.Header />
                        <Modal.Body>
                            <div className="text-center">
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400 flex flex-col justify-center justify-items-center">
                                    <FontAwesomeIcon icon={faCircleExclamation} className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200 m-auto mb-3" />
                                    Are you sure you want to delete this lesson ?
                                </h3>
                                <div className="flex justify-center gap-4">
                                    <TwButton
                                        onClick={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            dispatch(generalTutorDeleteLesson({
                                                hid: targetGeneralTutorLessonHid,
                                                accessToken: 'accessToken' in user && user.accessToken ? user.accessToken : ''
                                            }))
                                            setDeleteLessonModal(false);
                                        }}
                                    >
                                        {"Yes, I'm sure"}
                                    </TwButton>
                                    <TwButton onClick={() => setDeleteLessonModal(false)}>
                                        No, cancel
                                    </TwButton>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            )}
        </>
    )
}
export function SubComponentFooter() {
    const user = useSelector((state: RootState) => state.user)
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    //
    let [visionModal, setVisionModal] = useState(urlParams.get('vision') !== null ? true : false)
    let [affiliateModal, setAffiliateModal] = useState(false)
    let [termsModal, setTermsModal] = useState(urlParams.get('terms') !== null ? true : false)
    let [privacyModal, setPrivacyModal] = useState(urlParams.get('privacy') !== null ? true : false)
    let [contactModal, setContactModal] = useState(urlParams.get('contact') !== null ? true : false)
    return (
        <div className='mt-96 p-8 pb-12 text-xs'>
            <div className='flex basis-3/12 flex-col md:flex-row m-auto justify-around'>
                <div className='mb-3 md:mb-0'>
                    <div>
                        Other
                    </div>
                    <div className='ml-2'>
                        <span className={`
							text-blue-500
							underline
							hover:text-blue-400
							hover:cursor-pointer
						`}
                            onClick={() => setVisionModal(true)}
                        >Vision</span>
                    </div>
                    {user.hid ? (
                        <div className='ml-2 hidden'>
                            <span className={`
							text-blue-500
							underline
							hover:text-blue-400
							hover:cursor-pointer
						`}
                                onClick={() => setAffiliateModal(true)}
                            >Affiliate Onboarding</span>
                        </div>
                    ) : (null)}
                </div>
                <div className='mb-3 md:mb-0'>
                    <div>
                        Contact Us
                    </div>
                    <div className='ml-2'>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <span className={`
							text-blue-500
							underline
							hover:text-blue-400
							hover:cursor-pointer
							ml-1
						`}
                            onClick={() => setContactModal(true)}
                        >Contact Us</span>
                        <br />
                        <FontAwesomeIcon icon={faTiktok} /> <TwAnchor href='https://www.tiktok.com/@coursely.ai?_t=8ghL635rOYz&_r=1'>Tiktok</TwAnchor> <br />
                    </div>
                </div>
                <div className='mb-3 md:mb-0'>
                    <div>
                        © {`${new Date().getFullYear()}`} Feynman Technologies LTD.
                    </div>
                    <div className='ml-2'>
                        <span className={`
							text-blue-500
							underline
							hover:text-blue-400
							hover:cursor-pointer
						`}
                            onClick={() => setPrivacyModal(true)}
                        >Privacy Policy</span><br />
                        <span className={`
							text-blue-500
							underline
							hover:text-blue-400
							hover:cursor-pointer
						`}
                            onClick={() => setTermsModal(true)}
                        >Terms Of Service</span><br />
                    </div>
                </div>
            </div>
            <Modal show={visionModal} onClose={() => setVisionModal(false)}>
                <Modal.Header>Our Vision</Modal.Header>
                <Modal.Body>
                    <PrivateComponentVision />
                </Modal.Body>
                <Modal.Footer>
                    <TwButton
                        className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                        onClick={() => setVisionModal(false)}>Close</TwButton>
                </Modal.Footer>
            </Modal>
            <Modal show={affiliateModal} onClose={() => setAffiliateModal(false)}>
                <Modal.Header>Become An Affiliate!</Modal.Header>
                <Modal.Body>
                    <PrivateComponentAffiliateOnBoarding setAffiliateModal={setAffiliateModal} />
                </Modal.Body>
                <Modal.Footer>
                    <TwButton
                        className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                        onClick={() => setAffiliateModal(false)}>Close</TwButton>
                </Modal.Footer>
            </Modal>
            <Modal show={termsModal} onClose={() => setTermsModal(false)}>
                <Modal.Header>Terms Of Service</Modal.Header>
                <Modal.Body>
                    <PrivateComponentTerms />
                </Modal.Body>
                <Modal.Footer>
                    <TwButton
                        className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                        onClick={() => setTermsModal(false)}>Close</TwButton>
                </Modal.Footer>
            </Modal>
            <Modal show={privacyModal} onClose={() => setPrivacyModal(false)}>
                <Modal.Header>Privacy Policy</Modal.Header>
                <Modal.Body>
                    <PrivateComponentPrivacy />
                </Modal.Body>
                <Modal.Footer>
                    <TwButton
                        className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                        onClick={() => setPrivacyModal(false)}>Close</TwButton>
                </Modal.Footer>
            </Modal>
            <Modal show={contactModal} onClose={() => setContactModal(false)}>
                <Modal.Header>Contact</Modal.Header>
                <Modal.Body>
                    <SubComponentContactForm />
                </Modal.Body>
                <Modal.Footer>
                    <TwButton
                        className={`
                                        p-1
                                        px-3
                                        rounded-md
                                        hover:bg-slate-300
                                        dark:hover:bg-slate-700
                                        float-right
                                    `}
                        onClick={() => setContactModal(false)}>Close</TwButton>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
